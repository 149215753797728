var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login h-100 bg-m-primary"},[(_vm.isLoading)?_c('loading'):_vm._e(),_c('div',{staticClass:"top min-h-100 d-flex flex-column"},[_vm._m(0),_c('div',{staticClass:"login-part flex-grow-1 w-100"},[_c('b-container',[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('b-form-input',{staticClass:"login-form",attrs:{"required":true,"placeholder":"Nama Lengkap"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('b-form-input',{staticClass:"login-form",attrs:{"required":true,"type":"email","placeholder":"Alamat Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',[_c('b-form-datepicker',{staticClass:"login-form",attrs:{"required":true,"date-format-options":{ year: 'numeric', month: 'long', day: 'numeric' },"show-decade-nav":true,"nav-next-decade":false,"hide-header":true,"locale":"id","placeholder":"Tanggal Lahir"},on:{"input":_vm.onClickDateofBirth},model:{value:(_vm.dateofbirth),callback:function ($$v) {_vm.dateofbirth=$$v},expression:"dateofbirth"}}),_c('img',{staticClass:"input-icon-right",attrs:{"src":require("@/assets/images/ic/calendar1.svg"),"alt":"","srcset":""}})],1),_c('div',[_c('b-form-select',{staticClass:"login-form",attrs:{"options":[
                    {value: null, text: 'Pilih Jenis Kelamin', disabled: true},
                    {value: 'L', text: 'Laki - Laki'},
                    {value: 'P', text: 'Perempuan'}
                  ]},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),_c('div',[_c('b-form-select',{staticClass:"login-form",attrs:{"options":_vm.gradeOptions},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}})],1),_c('b-input-group',{staticClass:"login-form"},[_c('b-input-group-prepend',[_c('b-button',{staticClass:"prepend-button"},[_vm._v("🇮🇩 +62")])],1),_c('b-form-input',{attrs:{"required":true,"maxlength":13,"type":"text","placeholder":"No Handphone"},on:{"keypress":_vm.isNumber},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=_vm._n($$v)},expression:"phonenumber"}})],1),_c('div',[_c('b-form-input',{staticClass:"login-form",class:{
                    'is-valid': _vm.password === _vm.confirmPassword,
                    'is-invalid': _vm.password !== _vm.confirmPassword
                  },attrs:{"required":true,"type":`${_vm.showPassword ? 'text' : 'password'}`,"placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('img',{staticClass:"input-icon-right",attrs:{"src":require(`@/assets/images/ic/show-toggle${_vm.showPassword ? '-on' : ''}.svg`),"alt":"","srcset":""},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})],1),_c('div',[_c('b-form-input',{staticClass:"login-form",class:{
                    'is-valid': _vm.password === _vm.confirmPassword,
                    'is-invalid': _vm.password !== _vm.confirmPassword
                  },attrs:{"required":true,"type":`${_vm.showConfirmPassword ? 'text' : 'password'}`,"placeholder":"Konfirmasi Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('img',{staticClass:"input-icon-right",attrs:{"src":require(`@/assets/images/ic/show-toggle${_vm.showConfirmPassword ? '-on' : ''}.svg`),"alt":"","srcset":""},on:{"click":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}}})],1),_c('b-button',{staticClass:"login-btn",attrs:{"type":"submit"},on:{"click":_vm.register}},[_vm._v(" DAFTAR ")])],1)],1)],1),(!_vm.isHidden)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","xs":"12"}},[_c('p',{staticClass:"err-msg"},[_c('b',[_vm._v("Terdapat kesalahan :")]),_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])}),0)])])],1):_vm._e(),_c('b-row',[_c('b-col',[_c('hr',{staticClass:"break-line"}),_c('p',[_vm._v(" Sudah punya akun? "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Masuk")])],1)])],1)],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"id":"logo","src":require("@/assets/logo-square.svg")}})]),_c('br')])
}]

export { render, staticRenderFns }
<template>
<v-main>
  <div class="access h-100">
      <b-container>
              <b-form-group>
                  <b class="text-input">Waktu Pengerjaan</b><br/>
                  <b-row  no-gutters>
                   <b-col cols="6" xs="6">
                     <b-form-datepicker v-model="value" 
                      :date-disabled-fn="dateDisabled" 
                      locale="en" placeholder="Mulai">
                     </b-form-datepicker>
                   </b-col>
                   <b-col cols="6" xs="6">
                      <b-form-datepicker v-model="value" 
                       :date-disabled-fn="dateDisabled" 
                       locale="en" placeholder="Selesai">
                      </b-form-datepicker>
                    </b-col>
                  </b-row>
                  <br/>
                  <b class="text-input">Aksesibilitas</b><br/>
                  <b-row no-gutters>
                   <b-col>
                     <b-button class="public" align-content="center" size="lg"><img class="img-public" src="@/assets/images/ic/public.svg">Public</b-button>
                   </b-col>
                   <b-col>
                      <b-button class="private" align-content="center" size="lg"><img class="img-private" src="@/assets/images/ic/private.svg">Private</b-button>
                   </b-col>
                  </b-row>
                  <br/>
                  <b-form-input
                  :required="true"
                  class="desc-form"
                  v-model="title"
                  placeholder="Enrolment Key">
                  </b-form-input>
              </b-form-group>
      </b-container> 
  </div>
 </v-main>
</template>

<script>

export default {
 name: "AccessCourse",
};
</script>

<style scopped>
.text-input{
 font-size: 0.7rem;
 float: left;
 color: #BBBBD6;
}
.public{
 background-color: #302EA7!important;
 color: #ffff!important;
 font-size: 1.0rem!important;
 border: none!important;
 width: 90%;
 height: 45px;
}
.img-public{
  padding-right: 5px;
  
}
.private{
 background-color: #ffff!important;
 color: #302EA7!important;
 font-size: 1.0rem!important;
 border-color: #ffff!important;
 width: 90%;  
 }
.img-private{
  padding-right: 5px;
  
}
</style>
const { CallService } = require("./MoodleWS")


/**
 * Check if user is enrolled in particular course
 * @param {int} UserId user id
 * @param {int} CourseID course id
 * @returns promise
 */
var isEnrolled = async function (UserId, CourseID) {
    var param = {
        userid: UserId
    }
    var enrolled = false
    await CallService('POST', 'core_enrol_get_users_courses', false, null, param)
        .then((r) => {
            if (r.data != null && r.data != undefined && Array.isArray(r.data)) {
                for (var i = 0; i < r.data.length; i++) {
                    if (r.data[i].id == CourseID) {
                        enrolled = true
                        break;
                    }
                }
            } else {
                enrolled = false
            }
        })
        .catch((e) => {
            console.log(e)
        })

    return enrolled
}

/**
 * 
 * @param {int} courseId Course id to be get enrollment method
 * @returns prmoise
 * "id": 6,
 * "courseid": 3,
 * "type": "self",
 * "name": "Self enrolment (Student)",
 * "status": true (if user not enrolled)
 */
var getEnrolmentMethod = async function (courseId) {
    var param = {
        courseid: courseId
    }

    return await CallService('GET', 'core_enrol_get_course_enrolment_methods', false, param);
}

/**
 * 
 * @param {int} instanceId Enrollment Method Instance Id
 * @returns promise
 */
var getInstanceInfo = async function(instanceId) {
    var param = {
        instanceid: instanceId
    }
    return CallService('GET', 'enrol_self_get_instance_info', false, param);
}

/**
 * 
 * @param {int} courseId course id to be enroled in
 * @param {int} instanceId enrolment instance id (from getInstanceInfo)
 * @param {String} pwd enrolment key
 * @returns promise
 */
var selfEnrol = async function(courseId,instanceId,pwd){
    var param = {
        courseid:courseId,
        instanceid:instanceId,
        password:pwd
    }

    return CallService('POST','enrol_self_enrol_user',false,null, param)
}
module.exports = { isEnrolled, getEnrolmentMethod, getInstanceInfo, selfEnrol }
<template>
  <v-sheet
    class="min-h-100 bg-m-primary overflow-hidden p-relative"
    style="padding-bottom: 40px"
  >
    <loading v-if="isLoading" topText="Mengunduh sertifikat" />
    <div class="top d-flex flex-column min-h-100">
      <b-container>
        <b-row>
          <b-col cols="1">
            <span class="hamburger" @click.stop="drawer = !drawer"></span>
          </b-col>
          <b-col class="text-left text-white">{{ course.displayname }}</b-col>
        </b-row>
        <br />
        <h4 class="text-left text-white">{{ module.name }}</h4>
      </b-container>
      <b-container class="primary-content-light flex-grow-1">
        <div class="text-left"><strong>Materi</strong></div>
        <br />
        <div class="text-justify" v-html="module.summary"></div>
        <v-divider />
        <div v-if="activities != null" class="text-left">
          <div v-for="activity in activities" :key="activity.id">
            <div v-if="activity.uservisible">
              <router-link
                v-if="activity.modname == 'assign'"
                :to="'assign/' + activity.id"
                ><activity
                  :activity="activity"
                  :completed="activity.is_completed"
              /></router-link>
              <router-link
                v-else-if="activity.modname == 'quiz'"
                :to="'quiz/' + activity.id"
                ><activity
                  :activity="activity"
                  :completed="activity.is_completed"
              /></router-link>
              <router-link
                v-else-if="activity.modname == 'page'"
                :to="'page/' + activity.id"
                ><activity
                  :activity="activity"
                  :completed="activity.is_completed"
              /></router-link>
              <div v-else-if="activity.modname == 'customcert'">
                <div
                  v-on:click="downloadCertificate(activity)"
                  v-if="activity.available"
                >
                  <activity
                    :activity="activity"
                    :completed="activity.is_completed"
                  />
                </div>
                <div v-else v-on:click="openModalReason(activity.reason)">
                  <activity
                    :activity="activity"
                    :completed="activity.is_completed"
                  />
                </div>
              </div>

              <div
                v-else-if="activity.modname == 'resource'"
                ><activity
                  :activity="activity"
                  :completed="activity.is_completed"
                  :token="tokenString"
              /></div>


              <activity
                v-else
                :activity="activity"
                :completed="activity.is_completed"
              />
            </div>
          </div>
        </div>
      </b-container>
    </div>
    <v-navigation-drawer class="text-left" v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-main-color"
            ><strong>{{ course.displayname }}</strong></v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <span class="close-blue" @click.stop="drawer = false"></span>
        </v-list-item-icon>
      </v-list-item>
      <br />
      <b-container>
        <div class="module-nav">
          <v-list dense>
            <div v-for="module in modules" :key="module.id">
              <router-link
                v-if="module.uservisible"
                :to="module.id.toString()"
                replace
              >
                <v-list-item link>
                  <v-list-item-icon>
                    <span
                      :id="'route-pointer-' + module.id"
                      class="grey-circle"
                    ></span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <p class="text-main-color">
                      {{ module.name }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </div>
          </v-list>
        </div>
        <v-list-item v-on:click="back">
          <v-list-item-icon>
            <span class="exit-blue"></span>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-main-color"
              ><strong>Kembali</strong></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </b-container>
    </v-navigation-drawer>
    <user-navigation />
  </v-sheet>
</template>

<script>
import { CallService } from "@/utils/MoodleWS";
import ActivitySmall from "@/components/ActivitySmall";
import { GetActivitiesStatus } from "@/utils/mod/Completition";
import UserNavigation from "../../../components/UserNavigation.vue";
import axios from "axios";
import fileDownload from "js-file-download";
import Loading from "../../../components/util/Loading.vue";
export default {
  name: "ModuleView",
  data() {
    return {
      drawer: null,
      course: Object,
      modules: null,
      module: Object,
      activities: null,
      modid: this.$route.params.modid,
      cid: this.$route.params.id,
      isLoading: false,
      tokenString:  `&token=${this.$cookies.get("token")}`,
    };
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    getModules: async function () {
      let id = this.$route.params.id;
      let uid = this.$cookies.get("user");
      let response;
      var params = {
        courseid: id,
      };
      await CallService("get", "core_course_get_contents", true, params)
        .then((r) => {
          response = r.data;
          return GetActivitiesStatus(id, uid);
        })
        .then((r) => {
          if (r.success) {
            r.data.statuses.forEach((status) => {
              response.forEach((module) => {
                module.modules.forEach((mod) => {
                  if (mod.id == status.cmid) {
                    mod.is_completed = status.state;
                  }
                });
              });
            });
          }
        });
      this.modules = response;
    },
    getCourse: async function () {
      var params = {
        "options[ids][0]": this.$route.params.id,
      };

      await CallService("get", "core_course_get_courses", true, params).then(
        (r) => {
          if (r.data.length > 0) {
            this.course = r.data[0];
          }
        }
      );
    },
    async downloadCertificate(module) {
      this.isLoading = true;
      let user = this.$cookies.get("profile");
      if (user != null && user != undefined) {
        user = user.fullname.replace(/[^a-zA-Z ]/g, "");
        user = user.replace(" ", "_");
      } else {
        user = "";
      }
      let date = Date.now();
      let filename = `Sertifikat_${user}_${date}.pdf`;
      let url = `https://dashboard.literaci.id/mod/customcert/mobile/pluginfile.php`;
      let param = {
        userid: this.$cookies.get("user"),
        certificateid: module.instance,
        token: this.$cookies.get("token"),
      };

      await axios({
        url,
        method: "GET",
        responseType: "blob",
        params: param,
      }).then((r) => {
        fileDownload(r.data, filename);
      });
      this.isLoading = false;
    },
    openModalReason(cmid) {
      let modname = null;
      this.modules.forEach((module) => {
        module.modules.forEach((activity) => {
          if (activity.id == cmid) {
            modname = activity.name;
          }
        });
      });
      alert(`Sertifikat tidak dapat diakses sebelum ${modname} diselesaikan!`);
    },
    updateModule: function (id) {
      if (this.modules != null && this.modules != undefined) {
        this.modules.forEach((element) => {
          if (element.id == id) {
            this.module = element;
            if (element.modules.length > 0) {
              this.activities = element.modules;
              this.activities.forEach((activity) => {
                if (activity.modname == "customcert") {
                  if (activity.availability == null) {
                    activity.available = true;
                  } else {
                    JSON.parse(activity.availability).showc.forEach((a, i) => {
                      if (!a) {
                        activity.available = false;
                        activity.reasoncmid = activity.availability.c[i];
                      } else {
                        activity.available = true;
                      }
                    });
                  }
                }
              });
            } else {
              this.activities = null;
            }
          }
        });
      }
    },
  },
  created() {
    this.getModules();
    this.getCourse();
  },
  beforeUpdate() {
    this.updateModule(this.$route.params.modid);
  },
  updated() {
    var id = this.$route.params.modid;
    var element = document.getElementById(`route-pointer-${id}`);
    if (element != null) {
      this.modules.forEach((item) => {
        if (item.id != id) {
          var itemDom = document.getElementById(`route-pointer-${item.id}`);
          if (itemDom != null) {
            itemDom.classList.remove("grey-circle");
            itemDom.classList.remove("blue-circle");
            itemDom.classList.add("grey-circle");
          }
        } else {
          element.classList.remove("blue-circle");
          element.classList.remove("grey-circle");
          element.classList.add("blue-circle");
        }
      });
    }
  },
  components: {
    activity: ActivitySmall,
    UserNavigation,
    Loading,
  },
};
</script>

<style scoped>
.module-nav {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #302ea7;
}
</style>

/**
 * Convert timestamp to Indonesian date time format
 * @param {timestamp} timestamp Timetamp in seconds 
 * @returns 
 */
var getLocale = function(timestamp = Date.now()) {
    var date = new Date(timestamp * 1000);
    var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };
    return date.toLocaleString("id-ID", options);
}

module.exports = { getLocale }
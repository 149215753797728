<template>
  <div class="login bg-m-primary">
    <loading v-if="isLoading" :topText="loadingText"></loading>
    <div class="top min-h-100 d-flex flex-column">
      <div>
        <div class="text-center">
          <img id="logo" src="@/assets/logo-square.svg" />
        </div>
        <h2 class="app-title"><strong>Literaci</strong></h2>
        <h5 class="app-sub-title" style="margin: 1rem 1rem 2rem 1rem">
          Media Pembelajaran Literasi Keuangan
        </h5>
      </div>
      <b-container class="login-part flex-grow-1 w-100">
        <b-row>
          <b-col> </b-col>
          <b-col sm="12" md="12" xs="12">
            <p v-if="!isHidden" class="err-msg">{{ errorMsg }}</p>
            <b-form-group>
              <b-form-input
                class="login-form"
                v-model="username"
                placeholder="E-mail"
              />
              <b-form-input
                class="login-form"
                type="password"
                v-model="password"
                placeholder="Kata sandi"
              />
              <router-link to="/forgot">
                <div class="forgot-password">
                  <small id="forgot-password">Lupa password?</small>
                </div></router-link
              >
              <b-button v-on:click="login" class="login-btn">MASUK</b-button>
            </b-form-group>
            <p>
              Belum punya akun?
              <router-link to="/register">Buat Akun</router-link>
            </p></b-col
          >
          <b-col></b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/util/Loading.vue";
import { getToken, getUserData, login } from "@/utils/mod/Auth";
export default {
  name: "Login",
  data: function () {
    return {
      isLoading: false,
      username: "",
      password: "",
      isHidden: true,
      errorMsg: "Error Message",
      loadingText: "Memasukkan anda...",
    };
  },
  methods: {
    logon: async function () {
      await login(this.username, this.password)
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    login: async function () {
      this.isLoading = true;
      this.isHidden = true;
      var self = this;
      if (this.username == "" || this.password == "") {
        this.errorMsg = "Username atau Kata Sandi tidak boleh kosong!";
        this.isHidden = false;
      } else {
        await getToken(this.username, this.password)
          .then((r) => {
            if (r.status == 200 && !r.data.error) {
              self.$cookies.set("token", r.data.token);
              return getUserData(self.username);
            } else {
              if (r.data.errorcode == "usernotconfirmed") {
                self.$router.push("/email-confirmation");
                let user = {
                  uname: self.username,
                  pwd: self.password,
                };
                self.$store.commit("setUserToBeConfirmed", user);
              } else {
                alert("Username atau password salah!");
                this.isLoading = false;
              }
            }
          })
          .then((r) => {
            if (r != null) {
              if (r.success) {
                self.$cookies.set("user", r.data.users[0].id);
                var profile = {
                  id: r.data.users[0].id,
                  username: r.data.users[0].username,
                  fullname: r.data.users[0].fullname,
                  email: r.data.users[0].email,
                  profileimageurlsmall: r.data.users[0].profileimageurlsmall,
                  profileimageurl: r.data.users[0].profileimageurl,
                  city: r.data.users[0].city,
                };
                self.$cookies.set("profile", JSON.stringify(profile));
                if (
                  self.$route.query.nextUrl != undefined &&
                  self.$route.query.nextUrl != null
                ) {
                  self.$router.replace(self.$route.query.nextUrl).catch((e) => {
                    console.log(e);
                  });
                } else {
                  self.$router.replace("/u").catch((e) => {
                    console.log(e);
                  });
                }
              } else {
                console.log(r);
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
  components: {
    Loading,
  },
};
</script>

<style scoped>
#logo {
  margin-top: 50px;
  max-width: 100px;
}

.err-msg {
  color: #f00;
}

.forgot-password {
  padding-right: 5px;
  color: #302ea7;
  /* override app default */
  text-align: right !important;
}

.app-title {
  color: #fff;
  margin-top: 50px;
}

.app-sub-title {
  color: #fff;
  margin-bottom: 50px;
}
.login-form {
  margin-bottom: 10px;
  /* override bootstrap default */
  border-radius: 10px !important;
  flex: 1 1 auto;
}

.login-part {
  background-color: #f1f1fa;
  padding: 20px;
  padding-top: 50px;
  border-radius: 20px 20px 0px 0px;
  margin: 0 auto;
}
.login {
  background-color: #f1f1fa;
  height: 100%;
}

.login-btn {
  color: #fff;
  /* override bootstrap default */
  background-color: #302ea7 !important;
  width: 100%;
  margin-top: 10px;
}
</style>
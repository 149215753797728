import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './registerServiceWorker'
import './plugins/vuetify'
import Vuetify from 'vuetify/lib'
import VueCookies from 'vue-cookies'


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(Vuetify)
Vue.use(VueCookies)
Vue.$cookies.config('1d')

Vue.config.devtools = true
Vue.config.debug = true

new Vue({
    router,
    store,
    vuetify: new Vuetify({}),
    render: h => h(App)
}).$mount('#app')
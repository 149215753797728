<template>
    <div id="user-view">
        <router-view class="user-view"/>
        <user-nav id="nav"/>
    </div>
</template>

<script>
import UserNavigation from '@/components/UserNavigation'
export default {
    name:'UserView',
    components:{
        'user-nav':UserNavigation
    }
}
</script>

<style scoped>
#user-view{
    background-color: #f1f1fa;
    min-height: 100%;
}
.user-view{
    padding-bottom: 56px;
}

#nav{
    position: fixed;
    bottom: 0;
}
#nav *{
    position: relative;
}
#nav .v-btn{
    height: inherit !important;
}
</style>
<template>
  <div id="course-detail" class="bg-m-primary min-h-100">
    <loading v-if="isLoading"/>
    <div class="top d-flex flex-column h-100">
      <b-container class="text-left text-white">
        <b-row align-v="center">
          <b-col cols="1" class="text-left">
            <span class="back-icon icon" v-on:click="kembali()"></span>
          </b-col>
          <b-col class="text-left">
            <span class="course-title"
              ><strong>{{ course.displayname }}</strong></span
            >
          </b-col>
        </b-row>
        <div>
          <span class="course-category">{{ course.categoryname }}</span>
        </div>
      </b-container>
      <b-container class="flex-grow-1 primary-content min-h-90">
        <b-row>
          <b-col
            ><router-link
              :to="'/course/' + this.$route.params.id"
              class="text-light-grey"
              exact
              replace
              ><strong>Overview</strong></router-link
            ></b-col
          >
          <b-col
            ><router-link
              :to="'/course/' + this.$route.params.id + '/materi'"
              class="text-light-grey"
              exact
              replace
              ><strong>Materi</strong></router-link
            ></b-col
          >
        </b-row>
        <router-view :courseId="this.$route.params.id" />
        <div class="enrolmentForm" v-if="!isUserEnrolled && selfEnrol">
          <form class="form" id="enrolmentForm">
            <input
              class="form form-control"
              placeholder="enrolment key"
              type="password"
              v-model="enrolmentKey"
              v-if="passwordRequired"
            />
            <b-btn
              v-on:click="enrolUser"
              class="btn bg-m-primary txt-18"
              style="margin-top: 2em"
              >Ikuti Course</b-btn
            >
          </form>
        </div>
        <div v-else-if="!selfEnrol">
          <p class="text-center">
            Maaf, anda tidak dapat bergabung dengan kelas ini. Harap hubungi
            admin untuk informasi lebih lanjut.
          </p>
        </div>
      </b-container>
    </div>
    <user-navigation></user-navigation>
  </div>
</template>
<script>
import { CallService } from "@/utils/MoodleWS";
import { isEnrolled, getInstanceInfo } from "@/utils/Enrollment";
import { getEnrolmentMethod, selfEnrol } from "../../../utils/Enrollment";
import UserNavigation from "../../../components/UserNavigation.vue";
import Loading from "@/components/util/Loading"

export default {
  components: { UserNavigation , Loading},
  name: "CourseDetail",
  data: () => {
    return {
      course: Object,
      category: Object,
      isUserEnrolled: true,
      instanceEnrol: null,
      enrolmentKey: null,
      passwordRequired: true,
      selfEnrol: true,
      isLoading: false
    };
  },
  methods: {
    getData: async function (id) {
      var params = {
        field: "id",
        value: id,
      };

      await CallService("get", "core_course_get_courses_by_field", true, params)
        .then((r) => {
          if (
            r.data.courses != null &&
            r.data.courses != undefined &&
            Array.isArray(r.data.courses)
          ) {
            if (r.data.courses.length > 0) {
              this.course = r.data.courses[0];
            }
          } else {
            console.log("FALSE");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    kembali: function () {
      this.$router.back();
    },
    getCategory: async function (id) {
      var params = {
        "criteria[0][key]": "id",
        "criteria[0][value]": id,
      };
      await CallService(
        "get",
        "core_course_get_categories",
        false,
        params
      ).then((resp) => {
        if (resp.data.length > 0) {
          this.category = resp.data[0];
        }
      });
    },
    enrolled: async function (id, cid) {
      await isEnrolled(id, cid).then((r) => {
        this.isUserEnrolled = r;
      });
    },
    getEnrolMethod: async function () {
      var self = this;
      await getEnrolmentMethod(this.$route.params.id)
        .then((r) => {
          if (r.success) {
            if (r.data.length > 0) {
              return getInstanceInfo(r.data[0].id);
            } else {
              self.selfEnrol = false;
              return {
                success: false,
                data: {
                  errorcode: "cannotenroll",
                },
              };
            }
          } else {
            alert(r.data.errorcode);
          }
        })
        .then((r) => {
          if (r.success) {
            self.instanceEnrol = r.data;
            if (self.instanceEnrol.enrolpassword) {
              self.passwordRequired = true;
            } else {
              self.passwordRequired = false;
            }
          } else {
            if (r.data.errorcode != "cannotenroll") {
              alert(r.data.errorcode);
            }
          }
        });
    },
    enrolUser: async function () {
      // var self = this;
      this.isLoading = true
      await selfEnrol(
        this.$route.params.id,
        this.instanceEnrol.id,
        this.enrolmentKey
      ).then((r) => {
        if (r.success) {
          if (r.data.status) {
            alert("SUKSES");
            this.$router.go();
          } else {
            alert(r.data.warnings[0].message);
          }
        } else {
          alert(r.data.errorcode);
        }
      });
      this.isLoading = false;
    },
  },
  created() {
    this.getData(this.$route.params.id);
    this.getEnrolMethod();
  },
  updated(){
    this.enrolled(this.$cookies.get("user"), this.$route.params.id);
  },
  watch: {
    "$route.params.id": {
      handler(newVal) {
        this.getData(newVal);
        this.enrolled(this.$cookies.get("user"), this.$route.params.id);
      },
    },
  },
};
</script>

<style scoped>
.course-title {
  font-size: 28px;
}

.course-category {
  font-size: 14px;
}

.enroll-btn {
  position: fixed;
  bottom: 1em;
  margin: 0 auto;
}
.enrolment-form {
  margin: 1rem;
}
</style>
<template>
  <div class="btn-add-course">
  <b-container align-center>
    <b-button block class="addcourse">BUAT COURSE</b-button>
  </b-container>
</div>
</template>

<script>
export default {
 name: "AddCourseButton",
};
</script>

<style scoped>
.addcourse{
 background-color: #302EA7!important;
 height: 44px;
 padding: 5px;
 border-color:  #302EA7!important;
 font-style: normal;
 font-weight: normal;
 font-size: 18px;
 line-height: 25px;
}
</style>
<template>
  <div style="padding-top: 4rem" class="container">
    <loading v-if="isLoading" />
    <div v-if="isConfirmed">
      <img src="@/assets/images/boy-success.svg" style="max-width: 50%" />
      <br />
      <h1 class="text-primary">Berhasil</h1>
      <p>Email anda telah terkonfirmasi!</p>
      <router-link to="/u">
        <button class="btn bg-m-primary text-white">Mulai Belajar!</button>
      </router-link>
    </div>

    <div v-if="isError">
      <img src="@/assets/images/404.svg" style="max-width: 50%" />
      <br />
      <h1 class="text-primary">Whoops!</h1>
      <p>{{ errmsg }}</p>
      <router-link to="/u">
        <button class="btn bg-m-primary text-white">Login!</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { KonfirmasiEmail } from "@/utils/mod/Auth";
import Loading from "../../components/util/Loading.vue";
export default {
  name: "ConfrimEmail",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: true,
      isConfirmed: false,
      isError: false,
      errmsg: "Terjadi kesalahan dalam konfirmasi email!",
    };
  },
  methods: {
    async Confirm() {
      let user = this.$route.query.uname;
      let token = this.$route.query.data;
      let vm = this;
      if (user == null || token == null) {
        this.isError = true;
      } else {
        await KonfirmasiEmail(user, token).then((r) => {
          if (r.success) {
            vm.isLoading = false;
            vm.isConfirmed = true;
            vm.isError = false;
          } else {
            if (
              r.data != null &&
              r.data.errorcode != null &&
              r.data.errorcode == "invalidconfirmdata"
            ) {
              vm.errmsg =
                "Terdapat kesalahan pada data konfirmasi email! Silahkan kirim ulang konfirmasi email lalu tekan tautan terbaru yang diberikan!";
              vm.isError = true;
            } else {
              console.log(r);
            }
          }
        });
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.Confirm();
  },
};
</script>
<template>
 <div id="course-intro" class="h-100 bg-m-primary">
  <v-container id="course-intro-header">
        <v-row class="top-row">
          <v-col cols="1" xs="1">
            <router-link to="">
              <img src="@/assets/images/ic/back.svg"/>
            </router-link>
          </v-col>
          <v-col  class="text-nav text-left text-white" cols="8" xs="8" align-self="left">
            <b>Introduction</b>
          </v-col>
        </v-row>
      </v-container>
   <br/>
   <b-container class="intro-content flex-grow-1 min-h-100">
     <h5 class="text-intro text-left">Umum</h5>
     <b class="text-input">Nama Materi</b>
      <b-form-input
                  :required="true"
                  class="intro-form"
                  v-model="title"
                  placeholder="Nama Materi">
                  </b-form-input>
     <br/>
     <b class="text-input">Ringkasan</b>

     <br/>
     <h5 class="text-intro text-left">Aktivitas</h5>
     <b class="text-input">Jenis Aktivitas</b>
      <b-form-select class="category"
                 v-model="selected"
                 :options="itemsactivity"
                 label="Pilih Aktivitas"
                 ></b-form-select><br/><br/>
      <b-button block class="btn-add ">
      <img class="img-add" src="@/assets/images/ic/addFile.svg">
      <b class="text-add">Tambah File</b>
      </b-button>
   </b-container>
  </div>
</template>

<script>
export default {
 name: "CourseIntroduction",
 data() {
      return {
        selected: null,
        itemsactivity: [
          { value: null, text: 'Pilih Aktivitas' },
          { value: '', text: 'Assignment' },
          { value: '', text: 'Quiz' },
          { value: '', text: 'Forum' },
          { value: '', text: 'Survey' },
        ]
      }
    }
}
</script>

<style scoped>
#course-intro {
  background: #302ea7;  
  background-image: url("~@/assets/images/accent/1.svg"),
    url("~@/assets/images/accent/2.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right top;
}
#course-intro-header{
  padding-top: 20px;
  color: #fff;
}
.top-row {
  padding: 0px
}
.text-nav{
  font-size: 1.2rem;
}
.intro-content {
  border-radius: 0px 0px 0px 0px;
  background:#f1f1fa;
}
.text-intro{
  font-size: 1.0rem;
  color: black;
  font-weight:bold;
}
.text-input{
  color: #BBBBD6;
}
.btn-add{
  background-color: transparent;
  border-style: dashed;
}
.img-add{
  width: 30px;
  height: 30px;
  padding: 0 2px;
}
.text-add{
  color: black;
  font-weight: normal;
}
</style>
<template>
  <div class="course-item">
    <b-container class="h-100">
      <b-row
        v-if="course.overviewfiles.length > 0"
        align-v="center"
        align-h="center"
        class="default-padding text-left"
      >
        <b-col
          align-v="center"
          align-h="center"
          cols="3"
          class="img text-center"
        >
          <b-img
            class="img"
            v-if="course.overviewfiles.length > 0"
            :src="course.overviewfiles[0].fileurl + `?token=` + token"
          />
        </b-col>
        <b-col cols="9">
          <strong class="text-left text-dark title">
            {{ course.fullname }}
          </strong>
          <div class="elipsize-1" v-html="course.summary"></div>
        </b-col>
      </b-row>
      <b-row
        v-else
        align-v="center"
        align-h="center"
        class="default-padding text-left"
      >
        <b-col>
          <strong class="text-left text-dark title">
            {{ course.fullname }}
          </strong>
          <div
            class="elipsize-1"
            id="course-item-summary"
            v-html="course.summary"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "CourseItem",
  data() {
    return {
      token: this.$cookies.get("token"),
    };
  },
  props: {
    course: Object,
  },
  mounted() {
    var self = this;
    $("#course-item-summary img").each(function () {
      var uri = `${$(this).attr("src")}?&token=${self.$cookies.get("token")}`;
      $(this).attr("src", uri);
      $(this).attr("style", "max-width:95% !important;height:auto;");
    });
  },
};
</script>

<style scoped>
.course-item {
  padding: 0.5em 0em;
  border-radius: 10px;
  margin: 10px 0px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  background: #fff;
  min-height: 100px;
}
.container {
  padding: 0px 15px 0px 15px !important;
}
.col-9 {
  padding: 0px 0px 0px 10px !important;
}
.title {
  font-size: 18px;
}
.img {
  border-radius: 5px;
  padding: 1px !important;
  align-content: center;
  max-width: 80px;
  max-height: 80px;
}
</style>

<template>
  <div id="user-dashboard" class="h-100 d-flex flex-column">
    <b-container id="user-dashboard-header">
      <v-row align="center" no-gutters>
        <v-col cols="2" xs="2" align-self="center">
          <img :src="ppUrl" class="avatar" />
        </v-col>
        <v-col cols="8" class="text-left" xs="8" align-self="center">
          <h5 style="margin-bottom: 0 rem !important; padding-left: 30px">
            Selamat Datang! <strong><br />{{ name }}</strong>
          </h5>
        </v-col>
        <v-col cols="2" xs="2" align-self="center" v-on:click="toNotif">
          <img src="@/assets/images/ic/notif.svg" />
        </v-col>
      </v-row>
      <div v-if="dataloaded">
        <div class="scrolling-wrapper">
          <latest-course
            v-for="item in mycourses"
            :key="item.id"
            v-on:click.native="go(item.id)"
            :course="item"
          />
        </div>
      </div>
    </b-container>
    <br />
    <b-container class="item-container flex-grow-1 min-h-80">
      <div v-if="!isError">
        <div v-if="dataloaded">
          <div v-if="courses.length > 0">
            <div class="text-left">
              <h5><strong>Course Terbaru</strong></h5>
            </div>
            <course-item
              class="scrolling-item"
              v-for="item in courses"
              v-on:click.native="go(item.id)"
              :key="item.id"
              :course="item"
            />
          </div>
        </div>

        <div v-else>
          <div class="text-center">Memuat data ...</div>
        </div>
      </div>
      <div v-else>
        <div class="text-center">
          <span><strong>Terjadi kesalahan :</strong></span><br/>
          <span>{{ errmsg }}</span>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import CourseItem from "@/components/CourseItem";
import LatestCourseVue from "@/components/LatestCourse";
import { CallService } from "@/utils/MoodleWS";
export default {
  name: "UserDashboard",
  data: function () {
    return {
      ppUrl:
        "https://www.pngfind.com/pngs/m/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png",
      name: "Nama Pengguna",
      courses: [],
      mycourses: [],
      current_page: 1,
      dataloaded: false,
      isError: false,
      isEnrolled: false,
      errmsg: null,
    };
  },
  components: {
    "course-item": CourseItem,
    "latest-course": LatestCourseVue,
  },
  methods: {
    getCourse: function () {
      // var me = this;
      var param = {
        criterianame: "search",
        criteriavalue: "",
      };

      CallService("POST", "core_course_search_courses", false, null, param)
        .then((r) => {
          this.dataloaded = false;
          if (r.success) {
            if (
              r.data.courses != null &&
              r.data.courses != undefined &&
              r.data.courses.length > 0
            ) {
              this.isError = false
              this.courses = r.data.courses;
              this.dataloaded = true;
            }
          }else{
            this.isError = true
            this.errmsg = r.err.message
          }
        })
        .catch((e) => {
          this.isError = true;
          this.errmsg = e.message;
          console.log(e);
        });
    },
    getMyCourses: async function () {
      this.dataloaded = false;
      var param = {
        userid: this.$cookies.get("user"),
      };
      CallService("POST", "core_enrol_get_users_courses", false, null, param)
        .then((r) => {
          if (r.data != null && r.data != undefined && Array.isArray(r.data)) {
            this.mycourses = r.data;
            this.dataloaded = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    go: function (id) {
      this.$router.push(`/course/${id}`);
    },
    toNotif() {
      this.$router.push("/u/notification");
    },
  },
  mounted() {
    this.getCourse();
    this.getMyCourses();
    var profile = this.$cookies.get("profile");
    this.name = profile.fullname;
    this.ppUrl = profile.profileimageurlsmall;
  },
};
</script>
<style scoped>
.user-navigation-button-primary {
  /* override default vuetify button color */
  background-color: #f00 !important;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper .scrolling-item {
  flex: 0 0 auto;
}

.avatar {
  border-radius: 50%;
  height: 60px;
  margin: 10px;
  width: 60px;
}

#user-dashboard-header {
  padding-top: 20px;
  color: #fff;
}

#user-dashboard {
  background: #302ea7;
  background-image: url("~@/assets/images/accent/1.svg"),
    url("~@/assets/images/accent/2.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right top;
}

.col {
  padding-left: 10px;
  padding-right: 10px;
}

.d-latest-course {
  transform: translateY(120px);
  position: absolute;
}

.v-application p {
  padding-bottom: 0 rem !important;
}

.item-container {
  background-color: #f1f1fa;
  border-radius: 20px 20px 0px 0px;
  padding-top: 1em;
}
</style>

const { CallService } = require("../MoodleWS")

/**
 * Get all quiz from a course
 * 
 * @param {int} coursid the course id
 * @returns promise
 */
var getQuizByCourse = async function(coursid) {

    var param = {
        courseids: [coursid]
    };

    return await CallService("GET", "mod_quiz_get_quizzes_by_courses", false, param);
}

/**
 * Get user quiz access for specific quiz
 * 
 * @param {int} quizid the quiz id
 * @returns promise
 */
var getQuizAccess = async function(quizid) {
    var param = {
        'quizid': quizid
    };

    return await CallService("GET", "mod_quiz_get_quiz_access_information", false, param);
}

/**
 * Get all question type used in a particular quiz
 * 
 * @param {int} quizid the quiz id
 * @returns promise
 */
var getQuizQuestionType = async function(quizid) {
    var param = {
        'quizid': quizid
    };

    return await CallService("GET", "mod_quiz_get_quiz_required_qtypes", false, param);
}

/**
 * Get all user attempts from a particular quiz
 * 
 * @param {int} quizid the quiz id
 * @param {string} status the attempt status (value : all, finished, unfinished)
 * @returns promise
 */
var getUserAttempts = async function(quizid, status) {
    var param = {
        'quizid': quizid,
        'status': status
    };

    return await CallService("GET", "mod_quiz_get_user_attempts", false, param);
}

/**
 * Get question  from specific attempt id and page. All the data required to save and proceed 
 * attempt wil be available through this function response
 * 
 * @param {int} attemptid user attempt id
 * @param {int} page question page get from response or from 0 for the first page
 * @returns promise
 */
var getAttemptData = async function(attemptid, page) {
    var param = {
        'attemptid': attemptid,
        'page': page
    };

    return await CallService("GET", "mod_quiz_get_attempt_data", false, param);
}

/**
 * Process responses during an attempt at a quiz and also deals with attempts finishing.
 * 
 * 
 * @param {int} attemptid 
 * User attempt id
 * @param {array} data
 * Data to be saved, must be atleast q[attempt]:[question number]_:answer (ex: q21:1_:answer) the value get from the actual user answer
 * and q[attempt]:[question number]_:sequencecheck (ex: q21:1_:sequencecheck) the value get from mod_quiz_get_attempt_data
 * @param {int} isfinish
 * Wether the attempt is finish or not (value 1 or 0) 
 * @param {int} istimeup 
 * Wether the attempt is timed up or not (value 1 or 0)
 * @returns prmois
 */

var proccessAttempt = async function(attemptid, data, isfinish = 0, istimeup = 0) {
    var param = {
        'attemptid': attemptid,
        'finishattempt': isfinish,
        'timeup': istimeup,
        'data': data
    }

    return await CallService("POST", "mod_quiz_process_attempt", false, null, param);
}


/**
 * Intended to use for autosave feature
 * 
 * @param {int} attemptid User attempt id
 * @param {array} data 
 *  * Data to be saved, must be atleast q[attempt]:[question number]_:answer (ex: q21:1_:answer) the value get from the actual user answer
 * and q[attempt]:[question number]_:sequencecheck (ex: q21:1_:sequencecheck) the value get from mod_quiz_get_attempt_data
 * @returns promise
 */
var saveAttempt = async function(attemptid, data) {
    var param = {
        'attemptid': attemptid,
        'data': data
    }

    return await CallService("POST", "mod_quiz_save_attempt", false, null, param);
}


/**
 * Start new quiz attempt
 * @param {int} quizid Quiz id to be attempted
 * @param {int} forcenew  Whether to force a new attempt or not. default to 0
 * @param {array} preflightdata   Preflight required data (like passwords). Default to empty array
 * @returns promise
 */
var startAttempt = async function(quizid, forcenew = 0, preflightdata = []) {
    var param = {
        'quizid': quizid,
        'preflightdata': preflightdata,
        'forcenew': forcenew
    }

    return await CallService("POST", "mod_quiz_start_attempt", false, null, param);
}

module.exports = {
    getQuizByCourse,
    getQuizAccess,
    getQuizQuestionType,
    getUserAttempts,
    getAttemptData,
    proccessAttempt,
    saveAttempt,
    startAttempt,
}

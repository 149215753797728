import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,
    userToBeConfirmed:null
  },
  mutations: {
    loggedIn(state,user){
      state.user = user
    },
    logout(state){
      state.user = null
    },
    setUserToBeConfirmed(state,user){
      state.userToBeConfirmed = user
    }
  },
  actions: {
  },
  modules: {
  },
  getters:{
    getToBeConfirmed : state => {
      return state.userToBeConfirmed
    }
  }
})

<template>
  <b-container class="text-dark latest-course scrolling-item">
    <b-row>
          <b-col cols="9" class="text-left">
            <strong>{{ course.fullname }}</strong>
          </b-col>
          <b-col cols="3">
            <img src="~@/assets/images/accent/3.svg" />
          </b-col>
        </b-row>
        <b-row v-if="course.overviewData != null && course.overviewData != undefined">
          <b-col cols="4"
            ><small>{{ course.overviewData.totalModule }} Mdl</small></b-col
          >
          <b-col cols="4"
            ><small>{{ course.overviewData.quizSection }} Qz</small></b-col
          >
          <b-col cols="4"
            ><small>{{ course.overviewData.totalModule }} Star</small></b-col
          >
        </b-row>
  </b-container>
</template>

<script>
export default {
  name: "LatestCourse",
  props: {
    course: Object,
  },
};
</script>

<style scoped>
.latest-course {
  background-color: #fcfcff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  width: 275px;
  display: inline-block;
  margin: 0em 1em 0em 0em;
}
</style>
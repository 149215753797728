<template>
  <jodit-editor v-model="textdata" v-on:input="updateValue($event)"/>
</template>
<script>
import "jodit/build/jodit.min.css";
import { JoditEditor } from "jodit-vue";
export default {
  name: "textEditor",
  props: ["value"],
  data(){
    return {
      textdata:this.value
    }
  },
  components: {
    JoditEditor,
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
  mounted() {
    // setInterval(function(){
    //   console.log(this.value)
    // }, 500)
  },
};
</script>
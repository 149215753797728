<template>
  <div id="view-assignment" class="bg-m-primary">
    <loading v-if="isLoading" :topText="loadingTopText"></loading>
    <loading v-if="!dataloaded" :topText="'Memuat Tugas'"></loading>
    <loading v-if="uploading" :topText="'Mengunggah FIle'"></loading>
    <div class="top d-flex flex-column min-h-100">
      <b-container>
        <b-row align-v="center">
          <b-col cols="1" v-on:click="back"
            ><span class="arrow-left"></span
          ></b-col>
          <b-col class="text-left text-white txt-24 elipsize-1">{{
            course.fullname
          }}</b-col>
        </b-row>
      </b-container>
      <b-container class="primary-content flex-grow-1 bg-m-third">
        <floating-text-editor
          :isOpen="commentDialogOpen"
          v-model="newComment"
          :buttonSuccessText="'Kirim Komentar'"
          :buttonFailedText="'Batal'"
          :onButtonFailed="openNewCommentDialog"
          :onClose="openNewCommentDialog"
          :onButtonSuccess="saveComment"
        />
        <p class="text-left"><b>Materi</b></p>
        <div v-html="assignment.intro" id="intro"></div>
        <v-divider />
        <div v-if="dataloaded && assignment.introattachments.length > 0">
          <p class="text-left"><b>Lampiran</b></p>
          <assignment-attachment
            v-for="(item, index) in assignment.introattachments"
            :key="index"
            :attachment="item"
          />
          <v-divider />
        </div>
        <div
          v-if="
            dataloaded &&
            submission != null &&
            submission.lastattempt.submission != undefined
          "
        >
          <!-- SUBMISSION STATUS -->
          <p class="text-left"><b>Status Pengumpulan</b></p>
          <b-row v-if="submission != null" class="text-left">
            <b-col cols="4" class="txt-14 text-grey">Status Pengumpulan</b-col>
            <b-col>{{
              submission.lastattempt.submission.statuspengumpulan
            }}</b-col>
          </b-row>
          <!-- GRADING STATUS -->
          <b-row v-if="submission != null && !newattempt" class="text-left">
            <b-col cols="4" class="txt-14 text-grey">Status Penilaian</b-col>
            <b-col>{{ submission.lastattempt.statuspenilaian }}</b-col>
          </b-row>
          <!-- TIME LEFT -->
          <b-row
            v-if="submission != null && assignment.duedate != 0"
            class="text-left"
          >
            <b-col cols="4" class="txt-14 text-grey">Batas Waktu</b-col>
            <b-col
              ><count-down v-model="assignment.duedate"></count-down
            ></b-col>
          </b-row>
          <!-- LAST EDIT -->
          <b-row v-if="submission != null && !newattempt" class="text-left">
            <b-col cols="4" class="txt-14 text-grey">Terahir diedit</b-col>
            <b-col>{{ submission.lastattempt.submission.terahiredit }}</b-col>
          </b-row>
          <!-- ONLINE TEXT -->
          <b-row
            v-if="
              submission != null && submission.lastattempt.submission.hastext
            "
          >
            <b-col cols="4" class="txt-14 text-left text-grey">Teks</b-col>
            <b-col cols="8" v-if="submission.lastattempt.submission.hastext">
              <div class="dashed-box" v-if="!isEditMode">
                <online-text
                  v-for="(item, index) in onlineText"
                  :key="index"
                  :value="item"
                />
              </div>
              <div v-else>
                <text-editor
                  v-for="(item, index) in onlineText"
                  :key="index"
                  v-model="item.text"
                >
                  <div>
                    <div
                      v-for="(item, index) in assignment.configs"
                      :key="index"
                    >
                      <span
                        class="text-danger"
                        v-if="
                          item.name == 'wordlimit' &&
                          item.value != null &&
                          item.value != 0
                        "
                        >Jumlah kata maksimal : {{ item.value }}</span
                      >
                    </div>
                  </div>
                </text-editor>
              </div>
            </b-col>
            <b-col v-else class="text-left">-</b-col>
          </b-row>
          <!-- FILE SUBMISSION -->
          <b-row v-if="submission != null" class="text-left">
            <b-col cols="4" class="txt-14 text-grey">Berkas</b-col>
            <b-col cols="8" v-if="submission.lastattempt.submission.hasfiles">
              <div v-if="!isEditMode">
                <div v-for="(item, index) in files" :key="index">
                  <a :href="item.fileurl + '?token=' + $cookies.get('token')">
                    <assignment-attachment :attachment="item" />
                  </a>
                </div>
              </div>

              <div v-if="isEditMode">
                <b-form-file
                  v-model="newFile"
                  placeholder="Pilih berkas atau drop disini"
                  drop-placeholder="Drop berkas disini"
                ></b-form-file>
                <div>
                  <div v-for="(item, index) in assignment.configs" :key="index">
                    <span
                      class="text-danger"
                      v-if="item.name == 'maxfilesubmissions'"
                      >Jumlah file maksimal : {{ item.value }}</span
                    >
                    <span
                      class="text-danger"
                      v-if="item.name == 'maxsubmissionsizebytes'"
                      >Ukuran file maksimal :
                      {{ item.value / 1024 / 1024 }} MB</span
                    >
                    <span
                      class="text-danger"
                      v-if="
                        item.name == 'filetypeslist' &&
                        item.value != null &&
                        item.value != ''
                      "
                      >Jenis File : {{ item.value }}</span
                    >
                  </div>
                </div>
              </div>
            </b-col>
            <b-col v-else>No Content </b-col>
          </b-row>
          <!-- ASSIGNMENT COMMENTS -->
          <b-row v-if="submission != null" class="text-left">
            <b-col cols="4" class="txt-14 text-grey">Komentar</b-col>
            <b-col cols="8" v-if="commentloaded">
              <comment
                v-for="(item, index) in comments"
                :key="index"
                :comment="item"
              />
              <div
                v-if="canpost"
                v-on:click="openNewCommentDialog()"
                class="dashed-box text-center padding-half-em text-main-color"
              >
                <div>
                  <span
                    class="
                      d-inline-block
                      ic-plus-blue
                      margin-h-1em
                      align-middle
                    "
                  ></span>
                  <span class="d-inline"
                    ><strong>&nbsp; Tambahkan Komentar</strong></span
                  >
                </div>
              </div>
            </b-col>
            <b-col v-else>-</b-col>
          </b-row>
          <!-- NEW ASSIGNMENT BUTTON -->
          <div
            v-on:click="toggleEditMode()"
            v-if="newattempt && !isEditMode"
            class="
              new-attachment-block
              text-center
              padding-half-em
              text-main-color
            "
          >
            <div>
              <span class="ic-plus-blue d-inline-block align-middle"></span>
              <span
                class="d-inline-block align-middle margin-horizontal-1"
                style="font-size: 1.2em"
                ><strong>Tambahkan Tugas</strong></span
              >
            </div>
          </div>
          <!-- EDIT ASSIGNMENT BUTTON -->
          <div v-else>
            <div
              v-if="!isEditMode"
              class="edit-submission-block w-80"
              v-on:click="toggleEditMode()"
            >
              EDIT PENGUMPULAN
            </div>
          </div>
          <div class="row" v-if="isEditMode">
            <b-col>
              <b-button
                class="bg-danger text-white w-100"
                v-on:click="toggleEditMode()"
                >Kembali</b-button
              >
            </b-col>
            <b-col>
              <b-button
                class="bg-m-primary text-white w-100"
                v-on:click="saveAttempt()"
              >
                Simpan
              </b-button>
            </b-col>
          </div>
        </div>
        <div v-else>
          <div>
            <p>Ups terjadi kesalahan :( harap muat ulang halaman ini!</p>
            <b-button class="bg-m-primary text-white" v-on:click="$router.go()"
              >Muat Ulang</b-button
            >
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import {
  getAssignment,
  getUserSubmission,
  Submit,
} from "@/utils/mod/Assignments";
import AssignmentAttachment from "@/components/AssignmentAttachment";
import { MoodleException } from "@/utils/Exception";
import pluginOnlineText from "@/components/moodleplugins/OnlineText";
import pluginComment from "@/components/moodleplugins/CommentsItem";
import { GetComments, AddComments } from "@/utils/mod/Comments";
import FloatingTextEditor from "../../../components/form/FloatingTextEditor.vue";
import TextEditor from "../../../components/form/TextEditor.vue";
import { Upload } from "@/utils/mod/Files";
import CountDown from "@/components/util/CountDown";
import Loading from "@/components/util/Loading";
import $ from 'jquery';
export default {
  name: "ViewAssignment",
  data() {
    return {
      course: Object,
      assignment: Object,
      dataloaded: false,
      submission: null,
      onlineText: null,
      commentloaded: false,
      comments: null,
      canpost: false,
      isLoading: false,
      loadingTopText: "Memuat tugas",
      files: [],
      commentDialogOpen: false,
      onlineTextDialogOpen: false,
      newComment: "<p>Tambahkan komentar baru</p>",
      isEditMode: false,
      newFile: null,
      newattempt: false,
      fileDraft: null,
      uploading: false,
    };
  },
  components: {
    "assignment-attachment": AssignmentAttachment,
    "online-text": pluginOnlineText,
    comment: pluginComment,
    FloatingTextEditor,
    TextEditor,
    CountDown,
    Loading,
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    async saveAttempt() {
      this.isLoading = true;
      this.loadingTopText = "Menyimpan Tugas";
      let plugindata = [];
      if (
        this.submission.lastattempt.submission.hasfiles &&
        this.fileDraft != null
      ) {
        plugindata["files_filemanager"] = this.fileDraft.itemid;
      }
      if (this.submission.lastattempt.submission.hastext) {
        plugindata["onlinetext_editor"] = [];
        plugindata["onlinetext_editor"]["text"] = this.onlineText[0].text;
        plugindata["onlinetext_editor"]["format"] = this.onlineText[0].format;
        plugindata["onlinetext_editor"]["itemid"] = this.onlineText[0].id;
      }
      await Submit(this.assignment.id, plugindata).then((r) => {
        if (!r.success) {
          console.log(r.data);
          alert(`Gagal menyimpan tugas! \n ${r.data.errorcode}`);
        }
      });
      this.isLoading = false;
      this.toggleEditMode();
      this.getusersubmissions();
    },
    getassignment: async function (params) {
      this.dataloaded = false;
      var self = this;
      await getAssignment(params)
        .then((r) => {
          if (r.data.courses != null && r.data.courses != undefined) {
            if (Array.isArray(r.data.courses)) {
              if (r.data.courses.length > 0) {
                r.data.courses.forEach((element) => {
                  if (element.id == params) {
                    self.course = element;
                    element.assignments.forEach((item) => {
                      if (item.cmid == self.$route.params.assignId) {
                        var date = new Date(item.duedate * 1000);
                        var options = {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        };
                        item.duedatelocale = date.toLocaleString(
                          "id-ID",
                          options
                        );
                        self.assignment = item;
                        self.getusersubmissions();
                      }
                    });
                  }
                });
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.dataloaded = true;
    },
    getusersubmissions: async function () {
      this.dataloaded = false;
      let vm = this;
      await getUserSubmission(this.assignment.id, this.$cookies.get("user"), 0)
        .then((r) => {
          if (r.success) {
            this.submission = r.data;
            if(this.submission.lastattempt === null || this.submission.lastattempt.submission == undefined){
              vm.getusersubmissions();
            }else{

              if (this.submission.lastattempt.submission.status == "new") {
                this.newattempt = true;
              this.submission.lastattempt.statuspenilaian =
                "Belum Mengumpulkan";
              this.submission.lastattempt.submission.statuspengumpulan =
                "Belum Mengumpulkan";

              var date = new Date(
                this.submission.lastattempt.submission.timemodified * 1000
              );
              var options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              };
              this.submission.lastattempt.submission.terahiredit =
                date.toLocaleString("id-ID", options);
              this.submission.lastattempt.submission.hasfiles = false;
              this.submission.lastattempt.submission.hastext = false;
              if (this.submission.lastattempt.submission.plugins.length > 0) {
                var plugins = this.submission.lastattempt.submission.plugins;
                plugins.forEach((item) => {
                  if (item.type == "file") {
                    item.fileareas.forEach((index) => {
                      if (index.area == "submission_files") {
                        this.files = index.files;
                        this.submission.lastattempt.submission.hasfiles = true;
                      }
                    });
                  } else if (item.type == "onlinetext") {
                    this.submission.lastattempt.submission.hastext = true;
                    this.onlineText = item.editorfields;
                  }
                });
              }
              this.dataloaded = true;
              this.getComment();
            } else {
              if (this.submission.lastattempt.graded) {
                this.submission.lastattempt.statuspenilaian = "Sudah dinilai";
              } else {
                this.submission.lastattempt.statuspenilaian = "Belum dinilai";
              }

              this.submission.lastattempt.submission.new = false;
              this.submission.lastattempt.submission.statuspengumpulan =
                "Dikumpulkan untuk dinilai";

              let date = new Date(
                this.submission.lastattempt.submission.timemodified * 1000
              );
              let options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              };
              this.submission.lastattempt.submission.terahiredit =
                date.toLocaleString("id-ID", options);
              this.submission.lastattempt.submission.hasfiles = false;
              this.submission.lastattempt.submission.hastext = false;
              if (this.submission.lastattempt.submission.plugins.length > 0) {
                let plugins = this.submission.lastattempt.submission.plugins;
                plugins.forEach((item) => {
                  if (item.type == "file") {
                    item.fileareas.forEach((index) => {
                      if (index.area == "submission_files") {
                        this.files = index.files;
                        this.submission.lastattempt.submission.hasfiles = true;
                      }
                    });
                  } else if (item.type == "onlinetext") {
                    this.submission.lastattempt.submission.hastext = true;
                    this.onlineText = item.editorfields;
                  }
                });
              }
              this.dataloaded = true;
              this.getComment();
            }
                    }
          } else {
            console.log("GAGALLL");
          }
        })
        .catch((e) => {
          if (
            e instanceof MoodleException &&
            e.exception.exception == "dml_missing_record_exception"
          ) {
            this.submission = {
              lastattempt: {
                statuspenilaian: "Belum dinilai",
                submission: {
                  statuspengumpulan: "Belum mengumpulkan",
                  terahiredit: "-",
                  hasfiles: false,
                  new: true,
                },
              },
            };

            this.dataloaded = true;
          }
        });
      this.dataloaded = true;
    },
    getComment: async function () {
      GetComments(
        undefined,
        this.assignment.cmid,
        undefined,
        this.submission.lastattempt.submission.id
      ).then((r) => {
        this.canpost = r.data.canpost;
        this.comments = r.data.comments;
        this.commentloaded = true;
      });
    },
    async saveComment() {
      this.loadingTopText = "Mengirimkan komentar kamu";
      this.isLoading = true;
      var vm = this;
      await AddComments(
        null,
        this.assignment.cmid,
        null,
        this.newComment,
        this.submission.lastattempt.submission.id
      )
        .then((r) => {
          if (r.success) {
            vm.getComment();
          }
        })
        .catch((e) => {
          console.log(e);
        });
      vm.commentDialogOpen = false;
      this.isLoading = false;
    },
    openNewCommentDialog() {
      if (this.commentDialogOpen) {
        this.commentDialogOpen = false;
      } else {
        this.commentDialogOpen = true;
      }
    },
    toggleEditMode() {
      if (this.isEditMode) {
        this.isEditMode = false;
      } else {
        this.isEditMode = true;
      }
    },
  },
  watch: {
    newFile: async function (val) {
      if (this.dataloaded && val != null && val != undefined) {
        var config = this.assignment.configs;
        var vm = this;
        var isError = false;
        config.forEach(function (item) {
          if (item.plugin == "file") {
            switch (item.name) {
              case "enabled":
                if (item.value == 0) {
                  console.log("cant input file!");
                  isError = true;
                  return;
                }
                break;
              case "maxsubmissionsizebytes":
                if (val.size > item.value) {
                  console.log("more than allowed file size!");
                  isError = true;
                  return;
                }
                break;
              case "maxfilesubmissions":
                if (vm.files.length + 1 > item.value) {
                  console.log("more than num files allowed!");
                  isError = true;
                  return;
                }
                break;
            }
          }
        });
        if (!isError) {
          this.uploading = true;
          await Upload(this.$cookies.get("user"), val)
            .then((r) => {
              if (r.success) {
                this.fileDraft = r.data;
                console.log(this.fileDraft);
              } else {
                alert("Upload gagal!");
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log("CANT UPLOAD");
        }
        this.uploading = false;
      } else {
        console.log("upload not ready");
        return;
      }
    },
  },
  mounted() {
    this.getassignment([this.$route.params.id]);
  },
  updated(){
    $("#intro img").each(function () {
      var uri = `${$(this).attr("src")}?&token=${self.$cookies.get("token")}`;
      $(this).attr("src", uri);
      $(this).attr("style", "max-width:95% !important;");
    });
  }
};
</script>
<template>
  <div id="teacher-profile-view">
    <div class="top">
      <div class="top-profile">
        <b-row>
          <b-col class="text-left text-white"><h5>Profil</h5> </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <router-link to="/test"
              ><v-img class="user-avatar" :src="avatar"></v-img
            ></router-link>
            <strong><br />{{ name }}</strong
            ><br />
            <small>{{ email }}</small>
          </b-col>
        </b-row>
      </div>
      <b-container class="teache-profile-view-nav text-left">
        <b-row>
          <b-col cols="12">Pengaturan Akun</b-col>
        </b-row>
        <b-row>
          <b-col cols="11">notifikasi Email</b-col>
          <b-col cols="1">></b-col>
        </b-row>
        <b-row>
          <b-col cols="11">Notifikasi Belajar</b-col>
          <b-col cols="1">></b-col>
        </b-row>
        <b-row>
          <b-col cols="12">Dukung Kami</b-col>
        </b-row>
        <b-row>
          <b-col cols="11">Tentang Literacy</b-col>
          <b-col cols="1">></b-col>
        </b-row>
        <b-row>
          <b-col cols="11">Frequently Asked Question</b-col>
          <b-col cols="1">></b-col>
        </b-row>
        <b-row>
          <b-col cols="11">Bagikan Literacy</b-col>
          <b-col cols="1">></b-col>
        </b-row>
        <b-row>
          <b-col cols="11">Aplikasi lain</b-col>
          <b-col cols="1">></b-col>
        </b-row>
        <b-row>
          <b-col v-on:click="logout()" class="text-center text-danger"
            ><b>Logout</b></b-col
          >
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeacherProfileView",
  data: () => {
    return {
      name: "Samuel L. Jackson",
      avatar:
        "https://upload.wikimedia.org/wikipedia/commons/a/a9/Samuel_L._Jackson_2019_by_Glenn_Francis.jpg",
      email: "amien@mail.com",
    };
  },
  methods: {
    logout: function () {
      this.$cookies.remove("user");
      this.$cookies.remove("token");
      this.$cookies.remove("profile");
      this.$router.replace("/login").catch(() => {});
    },
  },
  mounted() {
    var profile = this.$cookies.get("profile");
    this.name = profile.fullname;
    this.avatar = profile.profileimageurlsmall;
    this.email = profile.username;
  },
};
</script>

<style scoped>
.user-avatar {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 0px auto;
  border: 2px solid #fff;
}
.top-profile {
  color: #fff;
  padding: 10px 20px 50px 20px;
}

#teacher-profile-view {
  background-color: #302ea7;
}
.teacher-profile-view-nav {
  border-radius: 20px 20px 0px 0px;
  background-color: #f1f1fa;
}
</style>
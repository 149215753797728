<template>
  <div id="test-page" style="height:100vh !important; width: 100% important; background-color:#00b140">

  </div>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    klik: async function () {
      var config = {
        method: "GET",
        url: "https://dashboard.literaci.id/login/index.php",
        mode: "cors",
        header: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Origin, Content-Type, Accept, Headers, Cookie",
          "Access-Control-Allow-Method":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Expose-Headers": "Set-Cookie",
           Cookie: "cookie1=value; cookie2=value; cookie3=value;"

        },
      };
      await axios(config)
        // await axios.get("https://dashboard.literaci.id/login/index.php",{withCredentials:true})
        .then((r) => {
          console.log(r.headers);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: "App",

  components: {
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@import url('./assets/style/App.css');
@import url('./assets/style/style.css');
html,
body {
  margin: 0px;
  min-height: 100%;
}

#app {
  font-family: Nunito;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0px;
  height: 100%;
}


.v-application--wrap {
  height: 100%;
}
</style>
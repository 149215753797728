<template>
<v-main>
  <div class="access h-100">
      <b-container>
        <theory-item/>
      </b-container>
  </div>
 </v-main>
</template>

<script>
import TheoryItem from '../../../components/TheoryItem.vue';
export default {
  components: { TheoryItem },
 name: "TheoryCourse",
};
</script>
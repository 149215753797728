const { CallService } = require('../MoodleWS')

var GetActivitiesStatus = async function(cid, uid){
    var param = {
        courseid: cid,
        userid:uid
    }
    return await CallService('GET','core_completion_get_activities_completion_status',false,param)
}

var MarkCompleted = async function(cmid){
    var param = {
        'cmid': cmid,
        'completed':1
    }
    return await CallService('GET','core_completion_update_activity_completion_status_manually',false,param)
}

var MarkNotCompleted = async function(cmid){
    var param = {
        'cmid': cmid,
        'completed':0
    }
    return await CallService('GET','core_completion_update_activity_completion_status_manually',false,param)
}

module.exports = {GetActivitiesStatus, MarkCompleted, MarkNotCompleted}
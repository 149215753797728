const { CallService } = require("../MoodleWS")

/**
 * 
 * @param {array} courseId 
 * @returns promise
 */
var getPageByCourse = async function(courseId){
    var param ={
        courseids : courseId
    }
    return await CallService('GET','mod_page_get_pages_by_courses',false,param)
}

module.exports = {getPageByCourse}
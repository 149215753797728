const axios = require('axios')
const cookie = require('vue-cookies')

/**
 * Call moodle web service
 * @param {string} method  HTTP method, default to GET
 * @param {string} service Service (function) name or wsfunction, default to 'rest'
 * @param {boolean} admin_required Wether the specific function required admin authorities or not, default to false
 * @param {object} inputParams String query parameters tobe url encoded, used in GET, default to null
 * @param {object} inputData Request BODY in json, used in POST, default to null 
 * @returns promise
 */
var CallService = async function(method = 'get', service = 'rest', admin_required = false, inputParams = null, inputData = null) {
    var params = {
        // wstoken: 'c6d0bff6ada3697e63c4622f90d8f25e',
        wstoken: cookie.get('token'),
        moodlewsrestformat: 'json',
        wsfunction: service
    }

    if (admin_required) {
        params['wstoken'] = 'c6d0bff6ada3697e63c4622f90d8f25e';
    } else {
        params['wstoken'] = cookie.get('token');
    }

    if (inputParams != null && inputParams != undefined) {
        for (var input in inputParams) {
            params[input] = inputParams[input];
        }
    }

    var conf = {
        method: method,
        url: 'https://dashboard.literaci.id/webservice/rest/server.php',
        mode:'cors',
        header: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
            'Access-Control-Allow-Method': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
        },
        params: params,
    }

    if (inputData != null && inputData != undefined) {
        conf['data'] = jsonToFormData(inputData)
    }

    var result = await axios(conf).then((r) => {
        if(r.data != null){

            if (r.data.exception != null && r.data.exception != undefined) {
                return { success: false, data: r.data };
            }
            else {
                return { success: true, data: r.data };
            }
        }else if(r.data == null && service == 'core_user_update_users'){
            return { success: true, data: 'no data' };
        }else{
            return { success: true, data: r.data };
        }
    }).catch((e) => {
        return { success: false, err: e };
    });

    return result;

}

/**
 * IDK just copy paste from stackoverflow
 * the point is to help convert JSON object to Multipart form data
 * @param {*} formData 
 * @param {*} data 
 * @param {*} parentKey 
 */
function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

/**
 * IDK just copy paste from stackoverflow
 * the point is to convert JSON object to Multipart form data
 * @param {object} data Json to be converted to multipart form data 
 * @returns Multipart
 */
function jsonToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}

module.exports = {
    CallService
}
const { CallService } = require("../MoodleWS")

/**
 * Upload file to draft
 * @param {int} userid user id
 * @param {File} file File to be uploaded
 * @returns promise
 */
var Upload = async function(userid, file) {
    var base64 = await toBase64(file)
        .then(r => {
            return r;
        })
        .catch((e) => {
            console.log(e)
        });
    var param = {
        itemid: 0,
        instanceid: userid,
        filearea: 'draft',
        filecontent: base64.split(',')[1],
        component: 'user',
        filepath: '/',
        filename: file.name,
        contextlevel: 'user'
    }
    return CallService('POST', 'core_files_upload', false, null, param);
}

var Upload64 = async function(userid, file, filename) {
    var param = {
        itemid: 0,
        instanceid: userid,
        filearea: 'draft',
        filecontent: file.split(',')[1],
        component: 'user',
        filepath: '/',
        filename: filename,
        contextlevel: 'user'
    }
    return CallService('POST', 'core_files_upload', false, null, param);
}

/**
 * Convert File to Base64
 * @param {File} file 
 * @returns promise
 */
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
})

module.exports = { Upload, Upload64, toBase64 }
<template>
  <v-bottom-navigation 
  color="primary"
  :value="value"
  >
        <router-link to="/u/">
          <v-btn color="white">
            <small>Beranda</small>
            <v-icon class="icon icon-home"></v-icon><br>
          </v-btn>
        </router-link>
        <router-link to="/u/search">
          <v-btn color="white">
            <small>Cari Course</small>
            <v-icon class="icon icon-search"></v-icon><br>
          </v-btn>
        </router-link>
        <router-link to="/u/course">
          <v-btn color="white">
            <span>Course</span>
            <v-icon class="icon icon-book"></v-icon><br>
          </v-btn>
        </router-link>
        <router-link to="/u/profile">
          <v-btn color="white">
            <span>Profil</span>
            <v-icon class="icon icon-user"></v-icon><br>
          </v-btn>
        </router-link>
  </v-bottom-navigation>
</template>
<script>
export default {
  name: "UserNavigation",
  data: function () {
    return {
      value: 0,
      listHiddenNavURL: [
        "/u/profile/edit",
        "/u/profile/edit/camera",
        "/u/profile/edit/crop",
      ]
    };
  },
  computed: {
    toggleHideClass(){
      const self = this;
      return this.listHiddenNavURL.some((url) => url === self.$route.path);
    }
  }
};
</script>

<style scoped>
.my-nav {
  height: 56px;
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
}
.col{
  padding: 5px !important;
}

</style>
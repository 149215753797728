import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Auth/Login.vue'
import Register from '@/views/Auth/Register.vue'
import EmailConfirmation from '@/views/Auth/EmailConfirmation.vue'
import UserDashboard from '@/views/User/Dashboard/UserDashboard.vue'
import UserView from '@/views/User/UserView'
import Handle404 from '@/components/Handle404'
import UserProfile from '@/views/User/Profile/UserProfile'
import UserProfileView from '@/views/User/Profile/UserProfileView'
import UploadPhoto from '@/views/User/Profile/UploadPhoto'
import CropImage from '@/views/User/Profile/CropImage'
import EditUserProfileView from '@/views/User/Profile/EditUserProfileView'
import UserSearch from '@/views/User/Search/UserSearch'
import UserCourseOngoing from '@/views/User/Course/UserCourseOngoing'
import UserCourseDone from '@/views/User/Course/UserCourseDone'
import UserCourse from '@/views/User/Course/UserCourse'
import CourseDetail from '@/views/User/Course/CourseDetail'
import TestPage from '@/views/TestPage'
import CourseDetailOverview from '@/views/User/Course/CourseDetailOverview'
import CourseDetailMateri from '@/views/User/Course/CourseDetailMateri'
import ModulView from '@/views/User/Module/ModulView'
import QuizInfo from '@/views/User/Quiz/QuizInfo'
import TeacherView from '@/views/Teacher/TeacherView'
import TeacherDashboard from '@/views/Teacher/Dashboard/TeacherDashboard'
import AddCourse from '@/views/Teacher/Course/AddCourse'
import AccessCourse from '@/views/Teacher/Course/AccessCourse'
import DescriptionCourse from '@/views/Teacher/Course/DescriptionCourse'
import TheoryCourse from '@/views/Teacher/Course/TheoryCourse'
import CourseIntroduction from '@/views/Teacher/Course/CourseIntroduction'
import TeacherProfile from '@/views/Teacher/Profile/TeacherProfile'
import TeacherProfileView from '@/views/Teacher/Profile/TeacherProfileView'
import AddSubmission from '@/views/User/Assignment/AddSubmission'
import { default as AssignmentView } from '@/views/User/Assignment/View'
import Attempt from '@/views/User/Quiz/Attempt'
import PageView from '@/views/User/Module/PageView'
import Eula from '@/views/Terms/Eula'
import About from '@/views/Terms/About'
import ConfirmEmail from '@/views/Auth/ConfirmEmail'
import Pong from '@/views/Ping/Pong'
import ForgotPassword from '@/views/Auth/ForgotPassword'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/ping',
        name: 'Ping',
        component: Pong
    },
    {
        path: '/test',
        name: 'Test',
        component: TestPage
    },
    {
        path: '/login',
        name: 'User Login',
        component: Login
    }, {
        path: '/register',
        name: 'Register User',
        component: Register
    }, {
        path: '/email-confirmation',
        name: 'Email Confirmation',
        component: EmailConfirmation
    },
    {
        path: '/u',
        component: UserView,
        meta: {
            requiresAuth: true,
            is_admin: false
        },
        children: [{
                name: 'UserView',
                path: '',
                component: UserDashboard
            },
            {
                path: 'profile',
                component: UserProfile,
                children: [{
                    name: 'UserProfile',
                    path: '',
                    component: UserProfileView
                },{
                    path: 'edit',
                    component: EditUserProfileView
                },{
                    path: 'edit/camera',
                    component: UploadPhoto
                },{
                    path: 'edit/crop',
                    component: CropImage
                }]
            },
            {
                path: 'course',
                component: UserCourse,
                children: [{
                        path: '',
                        name: 'User Course',
                        component: UserCourseOngoing
                    },
                    {
                        path: 'done',
                        component: UserCourseDone
                    }
                ]
            },
            {
                path: 'search',
                component: UserSearch
            },
            {
                path:'notification',
                component: () => import('@/views/User/Notification/Notification')
            }
        ]
    }, {
        path: '/course/:id',
        component: CourseDetail,
        children: [{
            path: '',
            name: 'CourseDetail',
            component: CourseDetailOverview
        }, {
            path: 'materi',
            component: CourseDetailMateri
        }],
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    }, {
        path: '/course/:id/mod/:modid',
        component: ModulView,
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    },{
        path: '/course/:id/mod/page/:pageid',
        component: PageView,
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    },

    {
        path: '/course/:id/mod/assign/:assignId',
        component: AssignmentView,
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    },
    {
        path: '/course/:id/mod/assign/:assignId/submission',
        component: AddSubmission,
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    }, {
        path: '/course/:id/mod/quiz/:quizId',
        component: QuizInfo,
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    },
    {
        path: '/course/:id/mod/quiz/:quizId/attempt/:attemptid',
        component: Attempt,
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    },
    {
        path: '/t',
        component: TeacherView,
        meta: {
            requiresAuth: true,
            is_admin: true
        },
        children: [{
            name: 'TeacherView',
            path: '',
            component: TeacherDashboard
        }, {
            path: 'teacherprofile',
            component: TeacherProfile,
            children: [{
                name: 'TeacherProfile',
                path: '',
                component: TeacherProfileView
            }]
        }]
    }, {
        path: '/addcourse',
        component: AddCourse,
        children: [{
                name: 'AddCourse',
                path: '',
                component: DescriptionCourse
            },
            {
                path: 'access',
                component: AccessCourse
            },
            {
                path: 'theory',
                component: TheoryCourse
            }
        ]
    }, {
        path: '/intro',
        component: CourseIntroduction
    }, {
        path: '*',
        component: Handle404
    },{
        path:'/terms',
        component: Eula
    },
    {
        path:'/about',
        component: About
    },
    {
        path:'/confirm',
        component: ConfirmEmail
    },
    {
        path:'/forgot',
        component: ForgotPassword
    }
]


const router = new VueRouter({
    routes,
    linkExactActiveClass: "exact-active",
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Vue.$cookies.get('token') == null || Vue.$cookies.get('token') == undefined) {
            next({
                path: '/login',
                query: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
});

export default router
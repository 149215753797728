<template>
  <div class="bg-m-primary min-h-100" style="padding-bottom:40px">
    <div class="top d-flex flex-column min-h-100">
      <b-container>
        <loading v-if="!dataloaded" :topText="loadingTxt"></loading>
        <b-row>
          <b-col cols="4">
            <div class="quiz-auto-layout">
              <div class="inside-quiz-aut-layout">
                <h2 v-if="dataloaded && quiz.attempts > 0">
                  {{ quiz.attempts }}
                </h2>
                <h2 v-else-if="dataloaded && quiz.attempts == 0">~</h2>
                <span>Kesempatan</span>
              </div>
            </div>
            <div class="quiz-auto-layout">
              <div class="inside-quiz-aut-layout">
                <h2 v-if="dataloaded && quiz.timelimit > 0">
                  {{ quiz.timelimit / 60 }}
                </h2>
                <h2 v-else-if="dataloaded">~</h2>
                <span>Menit</span>
              </div>
            </div>
            <div class="quiz-auto-layout">
              <div class="inside-quiz-aut-layout">
                <h1>0</h1>
              </div>
            </div>
          </b-col>
          <b-col cols="8">
            <span class="boy-2"></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-left text-white">
            <span class="txt-24">Ayo</span><br />
            <span class="txt-32"><strong>Tes Pemahamanmu!</strong> </span>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="primary-content flex-grow-1">
        <div v-if="dataloaded" class="text-left">
          <h1 class="text-main-color">{{ quiz.name }}</h1>
          <div v-html="quiz.intro"></div>
          <p class="text-grey">Lihat Selengkapnya</p>
          <hr />
          <!-- MENAMPILKAN WAKTU MULAI KUIS    -->
          <div v-if="quiz.timeopen != 0" class="row">
            <div class="col-sm-2">Kuis dimulai pada :</div>
            <div class="col-sm-10">{{ quiz.timeopenlocale }}</div>
          </div>
          <!-- MENAMPILKAN WAKTU DITUTUP KUIS -->
          <div v-if="quiz.timeclose != 0" class="row">
            <div class="col-sm-2">Kuis ditutup pada :</div>
            <div class="col-sm-10">{{ quiz.timecloselocale }}</div>
          </div>

          <!-- MENAMPILKAN WAKTU PENGERJAAN -->
          <div class="row">
            <div class="col-sm-2">Waktu Pengerjaan</div>
            <div class="col-sm-10">
              <p v-if="quiz.timelimit == 0">Tidak dibatasi</p>
              <p v-else>{{ quiz.timelimit / 60 }} Menit</p>
            </div>
          </div>

          <!-- MENAMPILKAN JENIS SOAL -->
          <div v-if="qtype.length > 0" class="row">
            <div class="col-sm-2">Jenis soal :</div>
            <div class="col-sm-10">
              <span v-for="(item, index) in qtype" :key="index" class="tag">{{
                item
              }}</span>
            </div>
          </div>
          <br />

          <!-- MENAMPILKAN TOMBOL MULAI  -->
          <div v-if="access.canattempt" class="row text-center">
            <div class="col-sm-12">
              <button
                v-if="isAnyInProgress"
                class="btn bg-m-primary text-white"
                style="width: 100%; max-width: 400px"
                v-on:click="Lanjutkan"
              >
                Lanjutkan
              </button>
              <button
                v-else
                class="btn bg-m-primary text-white"
                style="width: 100%; max-width: 400px"
                v-on:click="newAttempt"
              >
                Mulai
              </button>
            </div>
          </div>

          <!-- MENAMPILKAN PERCOBAAN USER -->
          <div v-if="attempts.length > 0">
            <hr />
            <p class="text-grey">Pekerjaan anda</p>
            <table class="table table-striped">
              <thead class="bg-m-primary text-white text-center">
                <td>Waktu mulai</td>
                <td>Waktu selesai</td>
                <td>Status</td>
                <td>Nilai</td>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="(item, index) in attempts"
                  :key="index"
                >
                  <td>{{ item.timestartlocale }}</td>
                  <td>{{ item.timefinishlocale }}</td>
                  <td>{{ item.state }}</td>
                  <td v-if="item.sumgrades == null">N/A</td>
                  <td v-else>{{ item.sumgrades }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-container>
    </div>
    <user-navigation></user-navigation>
  </div>
</template>

<script>
import Loading from "../../../components/util/Loading.vue";
import {
  getQuizByCourse,
  getQuizAccess,
  getQuizQuestionType,
  getUserAttempts,
  startAttempt,
} from "@/utils/mod/Quiz";
import { getLocale } from "@/utils/DateTime";
import UserNavigation from '../../../components/UserNavigation.vue';
export default {
  name: "QuizInfo",
  data() {
    return {
      dataloaded: false,
      quiz: null,
      attempts: null,
      qtype: null,
      access: null,
      isAnyInProgress: false,
      inProgressAttempt: null,
      loadingTxt : 'Memuat kuis'
    };
  },
  components: { Loading, UserNavigation },
  methods: {
    async getQuizes() {
      await getQuizByCourse(this.$route.params.id)
        .then((r) => {
          if (r.success) {
            var quizes = r.data.quizzes;
            quizes.forEach((element) => {
              if (element.coursemodule == this.$route.params.quizId) {
                this.quiz = element;
                if (this.quiz.timeopen != 0) {
                  this.quiz.timeopenlocale = getLocale(this.quiz.timeopen);
                }
                if (this.quiz.timeclose != 0) {
                  this.quiz.timecloselocale = getLocale(this.quiz.timeclose);
                }
                getQuizAccess(this.quiz.id)
                  .then((r) => {
                    if (r.success) {
                      this.access = r.data;
                    } else {
                      this.access = [];
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
                getQuizQuestionType(this.quiz.id)
                  .then((r) => {
                    if (r.success) {
                      this.qtype = r.data.questiontypes;
                    } else {
                      this.access = [];
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
                getUserAttempts(this.quiz.id, "all")
                  .then((r) => {
                    if (r.success) {
                      this.attempts = r.data.attempts;
                      this.attempts.forEach((item) => {
                        item.timestartlocale = getLocale(item.timestart);
                        if (item.timefinish == 0) {
                          item.timefinishlocale = "N/A";
                        } else {
                          item.timefinishlocale = getLocale(item.timefinish);
                        }
                        item.timemodifiedlocale = getLocale(
                          item.timemodifiedlocale
                        );
                        if (item.state == "inprogress") {
                          this.isAnyInProgress = true;
                          this.inProgressAttempt = item;
                        }
                      });
                    } else {
                      this.access = [];
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
                return;
              }
            });
          }else{
            console.log(r.data)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    isLoaded() {
      if (
        this.quiz != null &&
        this.attempts != null &&
        this.qtype != null &&
        this.access != null
      ) {
        this.dataloaded = true;
      } else {
        this.dataloaded = false;
      }
    },
    async newAttempt(){
      var vm = this;
      vm.loadingTxt = 'Mempersiapkan kuis';
      vm.dataloaded = false;
      await startAttempt(this.quiz.id)
        .then(r=>{
          vm.dataloaded = true;
          if(r.success){
            vm.$router.replace({
              'path':`${vm.quiz.coursemodule}/attempt/${r.data.attempt.id}`
            })
          }else{
            console.log(r.data)
          }
        })
        .catch(e=>{
            console.log(e)    
          });
    },
    Lanjutkan(){
      this.$router.replace(this.$route.params.quizId+'/attempt/' + this.inProgressAttempt.id)
    } 
  },
  watch: {
    quiz: function () {
      this.isLoaded();
    },
    attempts: function () {
      this.isLoaded();
    },
    qtype: function () {
      this.isLoaded();
    },
    access: function () {
      this.isLoaded();
    },
  },
  mounted() {
    this.getQuizes();
  },
};
</script>
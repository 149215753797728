<template>
    <div class="attachment text-left">
        <div class="elipsize-1" style="width:100%">{{attachment.filename}}<br>
        <small>{{
            Math.round(((parseInt(attachment.filesize) / 1024) + Number.EPSILON) * 100) / 100}} KB</small>
        </div>
    </div>
</template>

<script>
export default{
    name:"AssignmentAttachment",
    props:['attachment'],    
}
</script>
<template>
    <div class="comment bg-m-fourth" >
        <div class="w-100">
            <div v-html="comment.avatar" class="d-inline comment-img">
            </div>
            <div class="d-inline">
                <small class="text-grey">{{comment.fullname}} - {{date}}</small>
                <hr>
                <div v-html="comment.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {getLocale} from "@/utils/DateTime"
export default {
    name:"pluginComment",
    props:['comment'],
    data(){
        return {
            date:getLocale(this.comment.timecreated)
        }
    },
    watch:{
        comment:function(){
            this.date = getLocale(this.comment.timecreated)
        }
    }
}
</script>

<style scoped>
.comment{
margin:0em 0em 1em 0em; 
padding: 0.25em 0.5em;
display: flex;
flex-direction: row;
}
.comment-img{
    margin: 0em 0.5em 0em 0em;
}
</style>
<template>
  <div class="handle-404">
    <img class="img-404" src="@/assets/images/404.svg" />
    <h1><strong><br>404</strong></h1>
    <p><strong>Maaf<br>Halaman yang anda tuju tidak tersedia</strong></p><br><br>
    <router-link to="/"><v-btn color="primary">BERANDA</v-btn></router-link>
  </div>
</template>

<script>
export default {
  name: "Handle404",
};
</script>

<style scoped>
.img-404 {
  margin-top: 50px;
}
</style>
<template>
  <div class="wrapper">
    <video
      class="video"
      :class="facingMode === 'user' ? 'front' : ''"
      ref="video"
    />
    <canvas style="display: none" ref="canva" />

    <!-- Front Camera Changer -->
    <button
      v-if="videoDevices.length > 1"
      class="button-take button is-rounded is-outlined switch-button"
      @click="switchCamera"
      :disabled="switchingCamera"
    >
      <b-icon pack="fas" icon="sync-alt" />
    </button>

    <!-- Bottom Button Container -->
    <div class="mx-4 pb-4 photo-button-container">
      <router-link to="/u/profile/edit">
        <button class="button-take button photo-button">
          <b-icon pack="fas" icon="arrow-left" />
        </button>
      </router-link>
      <button class="mx-4 button-take button photo-button" @click="TakePhoto">
        <b-icon pack="fas" icon="camera-fill" />
      </button>
    </div>
    <!-- <photos-gallery class="gallery" :photos="photos" /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      photos: [],
      mediaStream: null,
      videoDevices: [],
      facingMode: "environment",
      counter: 0,
      switchingCamera: false,
    };
  },
  methods: {
    async StartRecording(facingMode) {
      this.facingMode = facingMode;
      let video = this.$refs.video;
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: facingMode },
      });
      video.srcObject = this.mediaStream;
      return await video.play();
    },
    async StopVideo() {
      const tracks = this.mediaStream.getVideoTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    async TakePhoto() {
      let video = this.$refs.video;
      let canva = this.$refs.canva;
      let width = video.videoWidth;
      let height = video.videoHeight;
      canva.width = width;
      canva.height = height;

      console.log(screen.width, screen.height);
      let ctx = canva.getContext("2d");

      ctx.save();
      if (this.facingMode === "user") {
        ctx.scale(-1, 1);
        ctx.drawImage(video, width * -1, 0, width, height);
      } else {
        ctx.drawImage(video, 0, 0);
      }
      ctx.restore();

      const photo = {
        id: this.counter++,
        src: canva.toDataURL("image/png"),
      };

      this.photos.push(photo);
      this.$emit('takePhoto', photo);
    },
    async switchCamera() {
      this.switchingCamera = true;
      this.StopVideo();
      await this.StartRecording(
        this.facingMode === "environment" ? "user" : "environment"
      );
      this.switchingCamera = false;
    },
  },
  async mounted() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.videoDevices = devices.filter((d) => d.kind === "videoinput");
    await this.StartRecording(
      this.videoDevices.length === 1 ? "user" : "environment"
    );
  },
  beforeDestroy() {
    this.StopVideo();
  }
};
</script>

<style scoped>
.video.front {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.wrapper {
  background-color: black;
  display: grid;
  width: 100%;
  height: calc(100% - 65px);
  height: -o-calc(100% - 65px); /* opera */
  height: -webkit-calc(100% - 65px); /* google, safari */
  height: -moz-calc(100% - 65px); /* firefox */
  grid-template-columns: [left] 90vw [bs] 5vw [es] 5vw [right];
  grid-template-rows: [top] 5vh [bs] 5vh [es] 60vh [middle] 10vh [bottom] 20vh [end];
  justify-items: center;
  overflow: hidden;
}

.video {
  height: 100vh;
  grid-column: left/right;
  grid-row: top / bottom;
  user-select: none;
  max-width: unset;
}

.switch-button {
  grid-column: bs / es;
  grid-row: bs / es;
  z-index: 5;
  border-radius: 100%;
  width: 6vh;
  height: 6vh;
  font-size: 2vh;
  margin-bottom: 10%;
}

.photo-button-container {
  grid-column: left / right;
  grid-row: bottom;
  z-index: 5;
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
}

.photo-button {
  width: 10vh;
  height: 10vh;
  border-radius: 100%;
}

.photo-button {
  font-size: 4vh;
  color: white;
}
.gallery {
  grid-column: left / right;
  grid-row: bottom / end;
}
.button-take {
  background-color: #302ea7 !important;
}
</style>

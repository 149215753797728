<template>
  <div id="user-profile-view">
    <loading v-if="isLoading" topText="Menyimpan Profile"></loading>
    <div class="top">
      <div class="navigationBar" :class="{ 'bg-transparent': showBackground }">
        <b-row>
          <b-col cols="1" class="text-left">
            <router-link to="/u/profile">
              <span class="back-icon icon"></span>
            </router-link>
          </b-col>
          <b-col class="text-left">
            <span class="course-title"><strong>Edit Profil</strong></span>
          </b-col>
        </b-row>
      </div>
      <div class="top-profile">
        <b-row>
          <b-col class="text-center">
            <div id="changePhoto" href="#" tabindex="0">
              <v-img class="user-avatar" :src="avatar"></v-img>
              <v-img
                class="icon-overlay"
                src="@/assets/images/ic/photo-camera.svg"
                style="opacity:0.7"
              ></v-img>
            </div>
            <b-popover
              target="changePhoto"
              triggers="click"
              placement="bottom"
              title="Pilih Foto"
            >
              <ul class="list-group custom-popover">
                <li class="list-group-item">
                  <router-link class="text-dark" to="/u/profile/edit/camera">
                    Ambil Foto
                  </router-link>
                </li>
                <li class="list-group-item">
                  <span class="text-dark" v-on:click="pilihFile()">Pilih dari Galeri</span>
                  <b-form-file
                    :State="Boolean(avatar)"
                    v-model="newavatar"
                    id="fotopengguna"
                    style="display:none"
                    accept="image/png, image/gif, image/jpeg"
                  />
                </li>

                <router-link to="/u/profile/edit/camera"> </router-link>
              </ul>
            </b-popover>
            <small class="pt-4">Ganti Foto</small>
          </b-col>
        </b-row>
      </div>
      <b-container class="user-profile-view-nav text-left">
        <b-row>
          <b-col>
            <b-form-group>
              <!-- Form -->
              <!-- Nama Lengkap -->
              <label class="small-label">Nama Lengkap</label>
              <b-form-input
                :required="true"
                class="login-form"
                v-model="fullname"
                placeholder="Nama Lengkap"
              />

              <!-- Alamat Email -->
              <label class="small-label">Alamat Email</label>
              <b-form-input
                disabled
                :required="true"
                type="email"
                class="login-form"
                v-model="email"
                placeholder="Alamat Email"
              />

              <!-- Tanggal Lahir -->
              <label class="small-label">Tanggal Lahir</label>
              <div>
                <b-form-datepicker
                  :required="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }"
                  :show-decade-nav="true"
                  :nav-next-decade="false"
                  :hide-header="true"
                  @input="onClickDateofBirth"
                  locale="id"
                  class="login-form"
                  v-model="dateofbirth"
                  placeholder="Tanggal Lahir"
                >
                </b-form-datepicker>
                <img
                  class="input-icon-right"
                  src="@/assets/images/ic/calendar1.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <!-- Jenis Kelamin -->
              <label class="small-label">Jenis Kelamin</label>
              <div>
                <b-form-select
                  :options="[
                    {
                      value: null,
                      text: 'Pilih Jenis Kelamin',
                      disabled: true,
                    },
                    { value: 'L', text: 'Laki - Laki' },
                    { value: 'P', text: 'Perempuan' },
                  ]"
                  v-model="gender"
                  class="login-form"
                >
                </b-form-select>
              </div>

              <!-- Kelas -->
              <label class="small-label">Kelas </label>
              <div>
                <b-form-select
                  :options="gradeOptions"
                  v-model="grade"
                  class="login-form"
                >
                </b-form-select>
              </div>

              <!-- No Handphone -->
              <label class="small-label">Nomor Handphone</label>
              <b-input-group class="login-form">
                <b-input-group-prepend>
                  <b-button class="prepend-button">🇮🇩 +62</b-button>
                </b-input-group-prepend>
                <b-form-input
                  :required="true"
                  :maxlength="13"
                  @keypress="isNumber"
                  type="text"
                  v-model.number="phonenumber"
                  placeholder="No Handphone"
                />
              </b-input-group>
              <b-button
                type="submit"
                v-on:click="updateProfile"
                class="login-btn"
              >
                Simpan
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/util/Loading";
import { UpdateUserProfile } from "@/utils/user/User";
import { toBase64 } from "@/utils/mod/Files";
import $ from "jquery";
// import { Upload } from "@/utils/mod/Files";
export default {
  name: "UserProfileView",
  data: () => {
    return {
      description: "",
      avatar: "",
      email: "",
      errors: [],
      errorMsg: "",
      isHidden: true,
      showPassword: false,
      showConfirmPassword: false,
      gradeOptions: [],
      fullname: "",
      gender: null,
      grade: null,
      dateofbirth: "",
      phonenumber: "",
      password: "",
      confirmPassword: "",
      navBarTransparent: true,
      isLoading: false,
      newavatar: null,
      fileDraft: null,
    };
  },
  components: {
    Loading,
  },
  computed: {
    showBackground() {
      return this.navBarTransparent;
    },
  },
  methods: {
    pilihFile() {
      $("#fotopengguna").click();
    },
    async updateProfile() {
      this.isLoading = true;
      let city = {
        gender: this.gender,
        grade: this.grade,
        phonenumber: `+62${this.phonenumber}`,
        dateofbirth: this.dateofbirth,
        city: this.city,
      };

      let firstname = this.fullname.split(" ").slice(0, 1);
      let lastname = this.fullname.split(" ").slice(1).join(" ");

      let user = {
        id: this.$cookies.get("user"),
        firstname: firstname[0],
        lastname: lastname,
        city: JSON.stringify(city),
      };
      let profileToBeSaved = this.$cookies.get("profile");
      profileToBeSaved.fullname = this.fullname;
      profileToBeSaved.city = user.city;

      await UpdateUserProfile(user)
        .then((r) => {
          console.log(r);
          user.fullname = user.firstname;
          this.$cookies.set("profile", JSON.stringify(profileToBeSaved));
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },
    kembali() {
      this.$router.back();
    },
    onClickDateofBirth(dob) {
      this.dateofbirth = dob;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateForm() {
      this.errors = [];

      if (!this.fullname) {
        this.errors.push("Mohon isikan nama lengkap siswa");
      }
      if (!this.email) {
        this.errors.push("Mohon isikan alamat email siswa");
      }
      if (!this.password || !this.confirmPassword) {
        this.errors.push("Mohon isikan password");
      }
      if (!this.dateofbirth) {
        this.errors.push("Mohon isikan tanggal lahir siswa");
      }
      if (!this.grade) {
        this.errors.push("Mohon isikan kelas siswa");
      }
      if (!this.gender) {
        this.errors.push("Mohon isikan jenis kelamin siswa");
      }
      if (!this.phonenumber) {
        this.errors.push("Mohon isikan no telepon kamu");
      }

      if (this.errors.length != 0) {
        this.isHidden = false;
      } else {
        this.isHidden = true;
      }
    },
  },
  mounted() {
    var profile = this.$cookies.get("profile");
    this.fullname = profile.fullname;
    this.avatar = profile.profileimageurlsmall;
    this.email = profile.username;
    try {
      let description = JSON.parse(profile.city);
      this.gender = description.gender;
      this.grade = description.grade;
      this.phonenumber = description.phonenumber.substring(3);
      this.city = description.city;
      this.dateofbirth = new Date(description.dateofbirth);
      this.gradeOptions = [
        { value: null, text: "Pilih Kelas Kamu", disabled: true },
        { value: "1", text: "SD Kelas 1" },
        { value: "2", text: "SD Kelas 2" },
        { value: "3", text: "SD Kelas 3" },
        { value: "4", text: "SD Kelas 4" },
        { value: "5", text: "SD Kelas 5" },
        { value: "6", text: "SD Kelas 6" },
        { value: "7", text: "SMP Kelas 7" },
        { value: "8", text: "SMP Kelas 8" },
        { value: "9", text: "SMP Kelas 9" },
      ];
    } catch (e) {
      this.gender = null;
      this.grade = null;
      this.phonenumber = null;
      this.city = null;
      this.dateofbirth = new Date(Date.now());
      this.gradeOptions = [
        { value: null, text: "Pilih Kelas Kamu", disabled: true },
        { value: "1", text: "SD Kelas 1" },
        { value: "2", text: "SD Kelas 2" },
        { value: "3", text: "SD Kelas 3" },
        { value: "4", text: "SD Kelas 4" },
        { value: "5", text: "SD Kelas 5" },
        { value: "6", text: "SD Kelas 6" },
        { value: "7", text: "SMP Kelas 7" },
        { value: "8", text: "SMP Kelas 8" },
        { value: "9", text: "SMP Kelas 9" },
      ];
    }

    window.document.onscroll = () => {
      //   let navBar = document.querySelector(".navigationBar");
      //   if (window.scrollY > navBar.offsetTop) {
      //     this.navBarTransparent = false;
      //   } else {
      //     this.navBarTransparent = true;
      //   }
    };
  },
  watch: {
    'newavatar': async function(val){
      this.isLoading = true
      let base = await toBase64(val)
        .then(r => {
          return r
        })
        .catch(function(){
          alert('Terdapat kesalahan pemrograman! Harap hubungi admin!')
        })

        localStorage.setItem("addPhotoProfile",base);
        this.isLoading = false
        this.$router.push('edit/crop')
    }
  },
};
</script>

<style scoped>
.user-avatar {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 0px auto;
  border: 2px solid #fff;
  margin-bottom: 10px;
}
.top-profile {
  color: #fff;
  padding: 50px 20px 30px 20px;
}

#user-profile-view {
  background-color: #302ea7;
}

.user-profile-view-nav {
  border-radius: 20px 20px 0px 0px;
  background-color: #f1f1fa;
  padding: 5px 25px 20px 20px;
}

.list-header {
  color: #a3a3a3;
}

.bi-calendar {
  display: none;
}

.b-calendar-inner,
.dropdown-menu {
  width: 100% !important;
}

.b-form-datepicker .h-auto:first-child {
  display: none;
}

.b-form-datepicker .dropdown-menu {
  transform: translate3d(0px, calc(50vh - 500px), 0px) !important;
}

.b-form-datepicker label {
  text-align: left;
  padding: 0.375rem 0.75rem !important;
}

.form-control,
.login-form,
.input-group-prepend,
.btn {
  border: none !important;
}

.login-btn {
  border: none !important;
  width: calc(100% - 2px);
}

.prepend-button {
  background-color: #302ea7 !important;
  color: white;
}

.form-control.is-valid,
.form-control.is-invalid {
  background-image: none !important;
}

.form-group {
  margin-bottom: 0px !important;
}

.break-line {
  margin: 5px 0 !important;
}

.small-label {
  font-size: 10px;
  color: #a3a3a3;
}

.icon-overlay {
  width: 40px;
  margin-top: -80px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  text-align: center;
}

.popover {
  text-align: center !important;
  border: none !important;
}

.custom-popover li {
  border: none !important;
  text-align: center !important;
}

.custom-popover .list-group-item:last-child {
  border-top: 1px solid #ccc !important;
}

.navigationBar {
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  padding: 10px 20px 0px;
  background-color: #302ea7;
  z-index: 1;
}

.bg-transparent {
  background-color: none !important;
}
</style>
<template>
  <div class="user-course d-flex h-100 flex-column text-light-grey">
    <div class="top">
      <v-container>
        <v-row class="top-row">
          <v-col class="text-left text-white" cols="12">
            <h5>My Course</h5>
          </v-col>
        </v-row>
      </v-container>
      <b-container class="content flex-grow-1">
        <b-row>
          <b-col class="text-center">
            <router-link class="text-menu text-light-grey" to="/u/course" exact replace
              ><b>Berlangsung</b>
            </router-link></b-col
          >

          <b-col class="text-center"
            ><router-link
              class="text-menu text-light-grey"
              to="/u/course/done"
              exact
              replace
              ><b>Selesai</b>
            </router-link></b-col
          >
        </b-row>
        <router-view />
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCourse",
  props: {
    courseId: Number,
  },
};
</script>

<style scoped>
.user-course {
  background: #302ea7;
}
.top-row {
  padding: 10px;
}
.top-title {
  font-size: 1.2rem;
}
.content {
  border-radius: 25px 25px 0px 0px;
  background: #f1f1fa;
}
.text-menu {
  font-size: 1.3rem;
}
</style>
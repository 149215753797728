<template>
  <div id="terms-condition" class="text-justify bg-m-primary">
    <div class="top d-flex flex-column min-h-100">
      <b-container class="text-left text-white">
        <!-- PAGE TITLE -->
        <b-row align-v="center">
          <b-col cols="1" class="text-left">
            <span class="back-icon icon" v-on:click="kembali()"></span>
          </b-col>
          <b-col class="text-left">
            <span
              ><strong><h4>Tentang Literaci</h4></strong></span
            >
          </b-col>
        </b-row>
      </b-container>
      <!-- CONTENT -->
      <b-container class="primary-content-light flex-grow-1">
          <h1>Literaci.id</h1>
<p>Version 1.0 Beta</p>
<p style="text-align: justify;"><span style="font-weight: 400;">Aplikasi Pembelajaran Literasi Keuangan Syariah merupakan sebuah learning management system android yang digunakan sebagai media pembelajaran Digital Literasi Keuangan Syariah. Pengguna yang aplikasi ini terbagi 2 (dua) yaitu guru dan siswa. Pada aplikasi ini, guru dapat melakukan manajemen terhadap materi dan siswa yang terdaftar di dalamnya. sedangkan siswa dapat mendaftar materi yang terdapat pada aplikasi. Apabila siswa berhasil menyelesaikan suatu materi, maka siswa tersebut akan mendapatkan penghargaan berupa sertifikat. </span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Tujuan Literaci.id adalah sebagai berikut</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Menjadi media pembelajaran keuangan khususnya pada literasi</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Mempermudah pengguna dalam mempelajari materi literasi dengan menggunakan aplikasi berbasis </span><strong><em>mobile</em></strong></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Mendapatkan feedback dari pengguna terkait materi literasi</span></li>
</ul>
<p><span style="font-weight: 400;">Hubungi kami :</span></p>
<a href:="mailto:halo@divistant.com">halo@divistant.com</a>
          </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {
    kembali() {
      this.$router.go(-1);
    },
  },
};
</script>
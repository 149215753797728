<template >
  <div id="add-submission" class="min-h-100 bg-m-primary">
    <loading v-if="isloading"></loading>
    <div class="d-flex min-h-100 flex-column top">
      <b-container>
        <b-row align-v="center">
          <b-col cols="1"><span class="arrow-left"></span></b-col>
          <b-col class="text-left text-white txt-24 elipsize-1">
            {{ courseName }}
          </b-col>
        </b-row>
      </b-container>
      <b-container class="primary-content flex-grow-1">
        <div v-if="!isloading">
          <div style="margin: 1em 0em;"  class="text-left" v-html="assignment.intro"></div>
        <div v-for="(item, index) in plugins" :key="index">
          <div style="margin: 1em 0em;"  v-if="item.name == 'onlinetext'">
            <text-editor :content="textcontent"></text-editor>
          </div>
          <div style="margin: 1em 0em;" v-if="item.name == 'file'">
            <b-form-file
            v-model="files"
            placeholder="Masukkan file"
            :state="Boolean(file)"
            ></b-form-file>
          </div>
        </div>
        </div>
        <!-- <text-editor :content="textcontent"></text-editor> -->
        <button class="btn btn-primary w-100">Serahkan</button>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from '../../../components/util/Loading.vue';
import TextEditor from "@/components/form/TextEditor.vue";
import { getAssignment } from "../../../utils/mod/Assignments";
export default {
  name: "addsubmission",
  data() {
    return {
      textcontent: "<h1>Hello World</h1>",
      assignment: null,
      courseName: null,
      plugins: [],
      files:null,
      isloading:true
    };
  },
  components: {
    "loading":Loading,
    "text-editor":TextEditor,
  },
  methods: {
    getAssignmentData: async function () {
      await getAssignment([this.$route.params.id]).then((r) => {
        var courses = r.data.courses;
        courses.forEach((course) => {
          if (course.id == this.$route.params.id) {
            this.courseName = course.fullname;
            course.assignments.forEach((assign) => {
              if (assign.cmid == this.$route.params.assignId) {
                this.assignment = assign;
                assign.configs.forEach((config) => {
                  this.addConfig(config);
                });
              }
            });
          }
        });
        this.isloading = false;
      });
      console.log(this.plugins);
    },
    addConfig: function (config) {
      if (this.plugins.length <= 0) {
        var newplugin = {};
        newplugin["name"] = config.plugin;
        newplugin[config.name] = config.value;
        this.plugins.push(newplugin);
      } else {
        var found = false;
        var foundIndex = 0;
        for (var i = 0; i < this.plugins.length; i++) {
          if (this.plugins[i].name == config.plugin) {
            found = true;
            foundIndex = i;
          }
        }

        if (found) {
          this.plugins[foundIndex][config.name] = config.value;
        } else {
          var addplugin = {};
          addplugin["name"] = config.plugin;
          addplugin[config.name] = config.value;
          this.plugins.push(addplugin);
        }
      }
    },
  },
  mounted() {
    this.getAssignmentData();
  },
};
</script>
<template>
  <div v-if="isOpen" class="floatingTextEditor">
    <div class="text-right w-100">
      <span
        class="close-white"
        v-on:click="onClose"
        style="margin: auto 0 1em auto"
      ></span>
    </div>
    <jodit-editor v-model="textData"  v-on:input="updateValue($event)" height="80%" :buttons="buttons"/>

    <div class="text-right w-100" style="margin: 1em 0em">
      <b-button
        v-on:click="onButtonFailed"
        class="btn btn-danger d-inline-block"
        style="margin: 0 1em"
        >{{ buttonFailedText }}</b-button
      >
      <b-button
        v-on:click="onButtonSuccess"
        class="btn bg-m-primary d-inline-block"
        >{{ buttonSuccessText }}</b-button
      >
    </div>
  </div>
</template>
<script>
import "jodit/build/jodit.min.css";
import { JoditEditor } from "jodit-vue";
export default {
  name: "textEditor",
  props: [
    "value",
    "onButtonSuccess",
    "onButtonFailed",
    "buttonSuccessText",
    "buttonFailedText",
    "isOpen",
    "onClose",
  ],
  data(){
    return{
       buttons: ['bold', 'underline', 'italic', 'link'],
       textData: this.value
    }
  },
  components: {
    JoditEditor,
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.floatingTextEditor {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}
</style>
const moodle3session = require('@/utils/session/MoodleSession');
const session = new moodle3session.MoodleSession('https://dashboard.literaci.id/');
const axios = require('axios')
const {CallService} = require('../MoodleWS')
// const cookie = require('vue-cookies')

/**
 * 
 * @param {String} username to be logged in
 * @param {String} password  for username
 */

var login = async function (username, password) {
    const moodleSession = await session.obtainMoodleSession(username, password);
    const stillValid = await session.isValid(moodleSession);

    if (stillValid) {
        // Vue.$cookies.set("MoodleSession", moodleSession, null, null, "https://literasi.divistant.com/")
        console.log(moodleSession)
        getToken(username,password)
    }
}

var getToken = async function(username, password){
    var conf = {
        method: 'GET',
        url: 'https://dashboard.literaci.id/login/token.php',
        mode:'cors',
        header: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
            'Access-Control-Allow-Method': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
        },
        params: {
            'username':username,
            'password':password,
            'service':'rest'
        },
    }

    const token = await axios(conf).then((r)=>{
            return r
        })
    return token
}

var checkSession = async function (session) {
    return await session.isValid(session);
}

var getUserData = async function(username){
    var data = {
        criteria:[{
            key:'username',
            value:username
        }]
    }


    return await CallService('POST','core_user_get_users',false,null,data)
}

var KonfirmasiEmail = async function(uname,token){
    var param = {
        'username':uname,
        'secret':token
    }

    return await CallService('GET','core_auth_confirm_user',true, param)

}

var SendForgotPasswordEmail = async function(email){
    var param = {
        'username':email,
        // 'email':email
    }

    return await CallService('GET','core_auth_request_password_reset',true, param)
}


module.exports = { login, checkSession,getToken, getUserData, KonfirmasiEmail, SendForgotPasswordEmail}
<template>
  <div v-touch:swipe.left="swipeHandler" class="home">
    <b-container class="text-main-color">
      <br />
      <h3><strong>{{title[position]}}</strong></h3>
      <br />
      <br />
      <img  :src="images[position]" />
      <br />
      <br />
      <h5>{{text[position]}}</h5>
      <div class="w-100" v-if="position != 2" style="position: fixed; bottom: 2.5rem;">
        <span v-if="position == 0" class="nav-rect-active"></span>
        <span v-else v-on:click="positionto(0)" class="nav-rect"></span>
        <span v-if="position == 1" class="nav-rect-active"></span>
        <span v-else v-on:click="positionto(1)" class="nav-rect"></span>
        <span v-if="position == 2" class="nav-rect-active"></span>
        <span v-else v-on:click="positionto(2)" class="nav-rect"></span>
      </div>
      <div>
        <span v-if="position > 0 && position < 2" class="intro-back" style="position: fixed; bottom: 1rem; left: 1rem" v-on:click="back"></span>
        <span v-if="position < 2" class="intro-next" style="position: fixed; bottom: 1rem; right: 1rem" v-on:click="next"></span>
      </div>
      <div v-if="position == 2"
      style="position: fixed; bottom: 1rem; right:25%; left:25%"
        ><b-button class="bg-m-primary w-100" v-on:click="toLogin"
          >Mulai Sekarang</b-button
        ></div
      >
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: function () {
    return {
      images: [
        require("@/assets/images/intro0.svg"),
        require("@/assets/images/intro1.svg"),
        require("@/assets/images/intro.svg"),
      ],
      text: [
        "Nyaman untuk dipelajari kapanpun dan dimanapun anda berada",
        "Lupakan tentang ribuan kertas. Pengetahuan ada digenggamanmu",
        "Pelajari suatu yang baru sekarang!",
      ],
      title:[
        'Kapanpun, Dimanapun',
        'Tersedia digenggamanmu',
        'Menyelami pengetahuan'
      ],
      position: 0,
    };
  },
  methods:{
    swipeHandler(){
      console.log("RUUNNNNN");
    },
    next(){
      if(this.position < 2){
        this.position++;
      }
    },
    back(){
      if(this.position > 0){
        this.position--;
      }
    },
    positionto(p){
      this.position = p;
    },
    toLogin(){
      this.$cookies.set('intro',1,'3y')
      this.$router.push('/login')
    }
  },
  components: {},
  created(){
    if (this.$cookies.get('intro') != null && this.$cookies.get('intro') != undefined || this.$cookies.get('intro')==0) {
            this.$router.push({
              path:'/u'
            })
        }
  }
};
</script>

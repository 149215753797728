<template>
    <div>
        <FromCamera 
            @takePhoto="takePhoto" 
            :class="{ 'd-none': toggleHideCamera }"/> 

        <div class="takenPicture" :class="{ 'd-none': !toggleHideCamera }">
            <img :src="selectedPhoto" alt="" srcset="">
            <b-button variant="danger" class="mx-2" size="lg" @click="removePhoto">
                <b-icon pack="fas" icon="x" />
            </b-button>
            <b-button variant="success" class=" mx-2" size="lg" @click="addUserPhoto">
                <b-icon pack="fas" icon="check" />
            </b-button>
        </div>            
    </div>
</template>

<script>
import FromCamera from '@/components/camera/FromCamera.vue'

export default {
    components: {
        FromCamera
    },
    data() {
        return {
            selectedPhoto: {}
        }
    },
    computed: {
        toggleHideCamera(){
            return this.selectedPhoto.length != null
        }
    },
    methods: {
        takePhoto(data) {
            this.selectedPhoto = data.src
        },
        removePhoto(){
            this.selectedPhoto = {}
        },
        addUserPhoto(){
            const router = this.$router;
            
            localStorage.setItem("addPhotoProfile", this.selectedPhoto)
            router.push('crop')
            // this.$emit('addPhotoProfile', this.selectedPhoto)
        }
    }
}
</script>

<style>
    #user-profile {
        padding-bottom: 0 !important;
    }

    .takenPicture {
        height: calc(100vh);
        width: 100%;
        overflow: hidden;
    }

    .takenPicture img {
        max-width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100vh;
        margin: 0vh;
    }

    .takenPicture button {
        bottom: 20vh;
        left: 0;
        position: relative !important;
        right: 0;
        margin: auto;
        width: 10vh;
        height: 10vh;
        border-radius: 100%;
        font-size: 4vh;
    }

    .primary-color {
        background-color: #302ea7 !important;
    }

    
</style>

<template>
  <div>
    <span v-if="!isOvertime" class="d-block">{{ sisawaktu }}</span>
    <span v-else class="text-danger d-block">Overtime {{ sisawaktu }}</span>
  </div>
</template>

<script>
export default {
  name: "CountDown",
  data() {
    return {
      sisawaktu: 0,
      isOvertime: false,
    };
  },
  props: ["value"],
  methods: {
    startTimer() {
      var vm = this;
      var timestamp = vm.value * 1000;
      var target = timestamp - Date.now();
      target = target/1000;
      setInterval(function () {
        target--;
        if(timestamp < 0){
            vm.isOvertime = true;
            target =  Math.abs(target);
        }
        var days = Math.floor(target / (24 * 60 * 60));
        var hours = Math.floor(target / (60 * 60)) % 24;
        var minutes = Math.floor(target / 60) % 60;
        var seconds = Math.floor(target / 1) % 60;
        if (days > 0) {
          vm.sisawaktu = `${days} Hari ${hours} Jam ${minutes} Menit ${seconds} Detik`;
        } else if (hours > 0) {
          vm.sisawaktu = `${hours} Jam ${minutes} Menit ${seconds} Detik`;
        } else if (minutes > 0) {
          vm.sisawaktu = `${minutes} Menit ${seconds} Detik`;
        } else if (seconds > 0) {
          vm.sisawaktu = `${seconds} Detik`;
        }
      }, 1000);
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>
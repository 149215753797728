<template>
 <v-main>
  <div class="desc h-100">
    <b-row>
            <b-col>
              <b-form-group>
                  <b-form-input
                  :required="true"
                  class="desc-form"
                  v-model="title"
                  placeholder="Judul">
                  </b-form-input>
                <br/>
                <b-form-select 
                 class="desc-form"
                 v-model="selected"
                 :options="items"
                 ></b-form-select>
                <br/><br/>
                <b-textarea class="desc"
                rows="5"
                placeholder="Ringkasan">
                </b-textarea>
                <br/>
                <b-textarea class="desc"
                rows="4"
                placeholder="Requirement">
                </b-textarea>
                <br/>
                <b-form-file accept=".jpg, .png, .gif"></b-form-file>
              </b-form-group>
            </b-col>
    </b-row>
          <b-row  v-if="!isHidden">
            <b-col  sm="12" md="12" xs="12">
              <p class="err-msg">{{ errorMsg }}</p>
            </b-col>
          </b-row>  
  </div>
 </v-main>
</template>

<script>
export default {
 name: "DescriptionCourse",
data() {
      return {
        selected: null,
        items: [
          { value: null, text: 'Kategori' },
          { value: '', text: 'Fisika' },
          { value: '', text: 'Bahasa Indonesia' },
          { value: '', text: 'Matematika' },
          { value: '', text: 'Bahasa Inggris' },
          { value: '', text: 'Biologi' },
          { value: '', text: 'Kimia' },
        ]
      }
    }
};
</script>

<style scopped>

</style>
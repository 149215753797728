<template>
  <div class="activity-small">
    <b-container>
      <b-row>
        <b-col cols="2">
          <b-img-lazy :src="activity.modicon" />
        </b-col>
        <b-col style="text-decoration: none; color: black">
          <strong>{{ activity.name }}</strong>
          <br />{{ activity.modplural }}
        </b-col>
        <b-col cols="1">
          <div v-if="completed">
            <i class="far text-success fa-check-square"></i>
          </div>
          <div v-else>
            <i class="far fa-square"></i>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="activity.modname == 'resource'">
        <b-col offset="2">
          <div v-for="content in activity.contents"
              :key="content.timecreated">
            <a
              v-if="content.type == 'file'"
              :href="content.fileurl + token"
            >
              {{ content.filename }}
            </a>
          <span v-else><em>Konten belum di dukung oleh literaci.id</em></span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ActivitySmall",
  props: ["activity", "completed", "token"],
};
</script>

<style scoped>
.activity-small {
  background-color: #f1f1fa;
  border-radius: 3px;
  margin: 0.5em 0em;
}
</style>

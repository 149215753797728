const { CallService } = require("../MoodleWS")
const { MoodleException } = require('../Exception')

var GetComments = async function(contextLevel = 'module', instanceId = 0, component = 'assignsubmission_comments', itemId = 0, area = 'submission_comments', page = 0, sortdirection = 'ASC') {
    var param = {
        'contextlevel': contextLevel,
        'instanceid': instanceId,
        'component': component,
        'itemid': itemId,
        'area': area,
        'page': page,
        'sortdirection': sortdirection,
    }
    return CallService('GET', 'core_comment_get_comments', false, param)
        .then((r) => {
            if (r.data.exception != undefined && r.data.exception != null) {
                var message = ""
                if (r.data.exception == "dml_missing_record_exception") {
                    message = "Data tidak ditemukan!"
                }
                throw new MoodleException(r.data.errorcode, r.data, message);
            } else {
                return r;
            }
        })
}

var AddComments = async function(contextLevel = 'module', instanceId = 0, component = 'assignsubmission_comments', content = '', itemId = 0, area = 'submission_comments') {
    var param = {
        comments: [{
            'content': content,
            'contextlevel': contextLevel != null ? contextLevel : 'module',
            'instanceid': instanceId,
            'component': component != null ? component : 'assignsubmission_comments',
            'itemid': itemId,
            'area': area != null ? area : 'submission_comments',
        }]
    }
    return CallService('POST', 'core_comment_add_comments', false, null, param);
}

module.exports = { GetComments, AddComments };
<template>
  <div class="loading-wrapper">
    <div class="content">
      <div class="spinner-border text-white" role="status" style="margin-bottom:1em"></div>
      <h3 v-if="topText" class="text-white">
        <b>{{ topText }}</b>
      </h3>
      <p v-if="bottomText" class="text-white">{{ bottomText }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: ["topText", "bottomText"],
  data() {
    return {
      animation: require("@/assets/images/loading_state.gif"),
    };
  },
};
</script>

<style scoped>
.content {
  margin: auto;
}

.loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
</style>
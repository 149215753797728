<template>
  <div id="CourseDetailMateri">
    <div v-for="item in course" :key="item.id">
        <div class="text-left course-materi" v-on:click="goToModule(item.id)" v-if="item.uservisible">
          <h6>
            <strong>{{ item.name }}</strong>
          </h6>
          <div class="elipsize-2" v-html="item.summary"></div>
        </div>
    </div>
  </div>
</template>

<script>
import { CallService } from "@/utils/MoodleWS";
import {getEnrolmentMethod} from "@/utils/Enrollment";
export default {
  name: "CourseDetailMateri",
  data: () => {
    return {
      course: Object,
      enrollment: null
    };
  },
  props: {
    courseId: String,
  },
  components: {},
  methods: {
    goToModule:function(id){
      if(!(this.enrollment.status === true)){
        this.$router.push(`/course/${this.$route.params.id}/mod/${id}`)
      }
    },
    getData: async function (id) {
      var params = {
        courseid: id,
      };

      await CallService("get", "core_course_get_contents", true, params)
        .then((r) => {
          if(r.success){
            this.course = r.data;
          }else{
            alert(r.data.errorcode)
          }
        })
        .catch((e) => {
          alert(e)
        });
    },
    getEnrolmentMehods : async function(){
      await getEnrolmentMethod(this.$route.params.id)
        .then(r=>{
          if(r.success){
            this.enrollment = r.data[0]
          }else{
            alert(r.data.exception)
          }
        });
    }
  },
  created() {
    this.getData(this.$props.courseId);
    this.getEnrolmentMehods();
  },
};
</script>

<style>
.course-materi{
    margin: 0.5em 1em 0em 1em;
    padding: 1em;
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0px 5px 6px 0px rgba(168,168,168,0.8);
}

.course-materi :active{
    box-shadow: none;
}
</style>
const { CallService } = require('../MoodleWS')
const { MoodleException } = require('../Exception')

/**
 * Get all assignment from a specific or list of courses
 * @param {array of int} cid specific course id
 * @returns promise
 */
var getAssignment = async function(cid) {
    var param = {
        courseids: cid,
        capabilities: [],
        includenotenrolledcourses: 0
    }

    return CallService('POST', 'mod_assign_get_assignments', false, param)


};

/**
 * Get user submission from a praticular assgnment
 * @param {int} assignid Assingment ID
 * @param {int} userid  User ID
 * @param {int} groupid (Optional) group id
 * @returns promise
 */
var getUserSubmission = async function(assignid, userid, groupid = 0) {
    var param = {
        "assignid": assignid,
        "userid": userid,
        "groupid": groupid
    }

    return CallService('POST', 'mod_assign_get_submission_status', false, param)
        .then((r) => {
            if (r.data.exception != undefined && r.data.exception != null) {
                var message = ""
                if (r.data.exception == "dml_missing_record_exception") {
                    message = "Data tidak ditemukan!"
                }
                throw new MoodleException(r.data.errorcode, r.data, message);
            } else {
                return r;
            }
        })
}

var Submit = async function(assignid, plugindata){
    var param = {
        assignmentid : assignid,
        plugindata
    }

    return CallService('POST','mod_assign_save_submission',false, null, param)
}

module.exports = { getAssignment, getUserSubmission, Submit }
<template>
  <div id="search" class="h-100 bg-m-primary">
    <div class="top d-flex flex-column min-h-100">
      <b-container>
        <b-row class="text-white text-left">
          <b-col><h5>Cari Course</h5></b-col>
        </b-row>
      </b-container>
      <b-container class="primary-content flex-grow-1">
        <b-form-input
          type="search"
          v-on:keyup.enter="search()"
          placeholder="Cari"
          v-model="q"
          debounce="500"
        />
        <br />
        <div v-if="dataloaded">
          <div v-if="courses.length > 0" class="text-left">
            <strong>Course</strong><br />
            <course-item
              v-for="item in courses"
              v-on:click.native="go(item.id)"
              :key="item.id"
              :course="item"
            />
          </div>
          <div v-else>
            <b-container>
              <b-row>
                <b-col>
                  <img center src="@/assets/images/boy1.svg" />
                  <br />
                  <br />
                  <br />
                  <h5>
                    <b class="text-grey"
                      >Ooops, yang kamu cari tidak ada nih!</b
                    >
                  </h5>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div v-else>
          <b-container>
            <b-row>
              <b-col>
                <img center src="@/assets/images/girl1.svg" />
                <br />
                <br />
                <br />
                <h5>
                  <b class="text-grey">Yuk cari course!</b>
                </h5>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import CourseItemVue from '@/components/CourseItem.vue';
import {CallService} from '@/utils/MoodleWS';
export default {
  name: "UserSearch",
  data: () => {
    return {
      q: "",
      courses: [],
      dataloaded: false,
    };
  },
  components: {
    "course-item": CourseItemVue,
  },
  methods: {
    search: function () {
      var q = this.q;
      var param = {
        criterianame:'search',
        criteriavalue:q
      }

      CallService('POST','core_course_search_courses',false,null,param)
      .then((r)=>{
        this.dataloaded = false;
        if(r.data.courses != null && r.data.courses != undefined && r.data.courses.length > 0){
          this.courses = r.data.courses
          this.dataloaded = true
        }else{
          this.dataloaded = true;
          this.courses = [];
        }
      })
      .catch((e)=>{
        console.log(e)
      })

    },
    go: function (id) {
      this.$router.push(`/course/${id}`);
    },
  },
};
</script>

<style scoped>
.user-search {
  background: #302ea7;
  min-height: 100vh;
}
</style>
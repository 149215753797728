<template>
  <div id="test-page" class="bg-m-primary" style="padding-bottom: 40px">
    <loading v-if="isLoading"></loading>
    <div class="top d-flex flex-column min-h-100">
      <b-container class="text-left text-white">
        <div class="row">
          <b-col cols="1" >
            <span class="icon icon-back" v-on:click="back"></span>
          </b-col>
          <b-col cols="11" class="text-left">
            <h4 v-if="page != null && page.name != undefined">
              {{ page.name }}
            </h4>
          </b-col>
        </div>
      </b-container>
      <b-container class="primary-content-light flex-grow-1">
        <div
          class="text-left"
          id="content"
          v-if="page != null && page.content != undefined"
          v-html="page.content"
        ></div>
        <div v-else>No Data</div>
      </b-container>
    </div>
    <user-navigation />
  </div>
</template>

<script>
import { getPageByCourse } from "@/utils/mod/Page";
import Loading from "../../../components/util/Loading.vue";
import $ from "jquery";
import UserNavigation from "../../../components/UserNavigation.vue";
import { MarkCompleted } from "@/utils/mod/Completition";

export default {
  components: { Loading, UserNavigation },
  name: "PageView",
  data() {
    return {
      page: null,
      isLoading: true,
    };
  },
  methods: {
    getPages: async function () {
      this.isLoading = true;
      var self = this;
      let cmid = self.$route.params.pageid;
      await getPageByCourse([this.$route.params.id])
        .then((r) => {
          if (r.success) {
            r.data.pages.forEach((element) => {
              if (element.coursemodule == self.$route.params.pageid) {
                self.page = element;
                self.completed(cmid)
              }
            });
          } else {
            console.log(r);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },
    async completed(cmid){
      await MarkCompleted(cmid)
       .then((r) => {
          if (!r.success) {
            alert("Terjadi kesalahan dalam menandai page selesai!");
          }
        });
    },
    back: function () {
      this.$router.go(-1);
    },
  },
  // watch: {
  //   page: function () {

  //   },
  // },
  mounted() {
    this.getPages();
  },
  updated() {
    var self = this;
    $("#content img").each(function () {
      var uri = `${$(this).attr("src")}?&token=${self.$cookies.get("token")}`;
      $(this).attr("src", uri);
      $(this).attr("style", "max-width:95% !important;");
    });
  },
};
</script>
const { CallService } = require("../MoodleWS")


/**
 * Get user enrolled course
 * @param {int} UserId 
 * @returns 
 */
var getMyCourses = async function(UserId) {
    var param = {
        userid: UserId
    }
    var result = await CallService("POST", "core_enrol_get_users_courses", false, null, param)
        .then((r) => {
            return r
        })
        .catch((e) => {
            return e
        });

    return result
}

module.exports = { getMyCourses }
<template>
  <div id="teacher-dashboard" class="h-100 d-flex flex-column">
    <div id="teacher-dashboard-header">
      <v-row align="center" no-gutters>
      <v-col cols="2" xs="2" align-self="center">
          <img src="ppUrl" class="avatar" />
        </v-col>
        <v-col cols="8" class="text-left" xs="8" align-self="center">
          <h5 style="margin-bottom: 0 rem !important; padding-left: 30px">
            Selamat Datang! <strong><br />{{ name }}</strong>
          </h5>
        </v-col>
        <v-col cols="2" xs="2" align-self="center">
          <img src="@/assets/images/ic/notif.svg" />
        </v-col>
      </v-row>
      <div v-if="dataloaded">
        <div class="scrolling-wrapper">
          <latest-course
            v-for="item in mycourses"
            :key="item.id"
            :course="item"
          />
        </div>
      </div>
    </div>
    <br />
    <b-container class="item-container flex-grow-1 min-h-100">
      <div v-if="dataloaded">
        <div class="text-left">
          <h5><strong>Course Lainnya</strong></h5>
        </div>
        <course-item
          class="scrolling-item"
          v-for="item in courses"
          :key="item.id"
          :course="item"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import CourseItem from "@/components/CourseItem.vue";
import LatestCourseVue from "@/components/LatestCourse.vue";

export default {
  name: "TeacherDashboard",
  data: function () {
   return{
    ppUrl: "https://www.pngfind.com/pngs/m/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png",
    name: "Nama Pengguna",
    courses: [],
    mycourse: [],
    current_page: 1,
    dataloaded: false,
   };
  },
  components: {
    "course-item": CourseItem,
    "latest-course": LatestCourseVue,
  },
  methods: {
    getCourse:function () {
      var me = this;
      var config = {
        method: "get",
        url: `${process.env.VUE_APP_APIBASE}/course/my/page/${me.current_page}`,
        params: {
          userId: this.$cookies.get("user"),
        },
      };
      this.axios(config).then((r) => {
        me.courses = r.data.data;
        me.dataloaded = true;
      });
    },
    getMyCourses: function () {
      var me = this;
      var config = {
        method: "get",
        url: `${process.env.VUE_APP_APIBASE}/course/my/`,
        params: {
          userId: this.$cookies.get("user"),
        },
      };
      this.axios(config).then((r) => {
        me.mycourses = r.data;
        me.dataloaded = true;
      });
    },
  },
  mounted() {
    this.getCourse();
    this.getMyCourses();
    var profile = this.$cookies.get("profile");
    this.name = profile.fullname;
    this.ppurl = profile.profileimageurlsmall;
  },
};
</script>

<style scoped>
.user-navigation-button-primary {
  /* override default vuetify button color */
  background-color: #f00 !important;
}

.avatar {
  border-radius: 50%;
  height: 60px;
  margin: 10px;
  width: 60px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper .scrolling-item {
  flex: 0 0 auto;
}

#teacher-dashboard-header {
  padding-top: 20px;
  color: #fff;
}

#teacher-dashboard {
  background: #302ea7;
  background-image: url("~@/assets/images/accent/1.svg"),
    url("~@/assets/images/accent/2.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right top;
}

.item-container {
  background-color: #f1f1fa;
  border-radius: 20px 20px 0px 0px;
}

.col {
  padding-left: 10px;
  padding-right: 10px;
}

.d-latest-course {
  transform: translateY(120px);
  position: absolute;
}

.v-application p {
  padding-bottom: 0 rem !important;
}
</style>
<template>
  <div id="Attempt" class="bg-m-primary min-h-100">
    <loading v-if="!isLoaded" :topText="loadingText"></loading>
    <loading
      v-if="!isQuestionLoaded && isLoaded"
      :topText="questionLoadingText"
    ></loading>
    <b-modal
      id="submit-confirm"
      title="Konfirmasi"
      hide-footer
      hide-header
      centered
    >
      <div style="position: relative">
        <div class="text-center">
          <span class="img-alert-1" style="margin: 2em auto 2em auto"></span>
          <h4 class="text-main-color">Perhatian!</h4>
          <p>Apaakah anda telah menyelesaikan kuis ini?</p>
        </div>
        <div class="row" style="margin: 2em auto 0 auto">
          <div class="col-6">
            <button
              v-on:click="finish"
              class="btn w-100 bg-m-fourth text-main-color"
            >
              Ya
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn w-100 bg-m-primary text-white"
              v-on:click="hideConfirm"
            >
              Batal
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="top d-flex flex-column min-h-100">
      <b-container class="text-white text-left">
        <div v-if="isLoaded">
          <b-row>
            <b-col
              ><h4>{{ quiz.name }}</h4></b-col
            >
            <b-col class="text-right" v-if="quiz.timelimit > 0"
              >Waktu sisa: <b> <count-down v-model="timeup"></count-down></b
            ></b-col>
          </b-row>
        </div>
      </b-container>
      <b-container class="attempt-area text-left flex-grow-1" id="attempt-area">
        <div v-if="isQuestionLoaded">
          <form
            class="form"
            id="attempt-data"
            method="POST"
            action="localhost/attempt.php"
          >
            <div v-for="(q, i) in attemptData.questions" :key="i">
              <div v-html="q.html"></div>
              <hr />
            </div>
          </form>
        </div>
        <button
          v-if="!currentPage < 1 && quiz.navmethod == 'free'"
          class="btn attempt-back-btn bg-m-primary text-white"
          v-on:click="previousQuestion"
        >
          Kembali
        </button>
        <button
          v-if="nextPage > 0"
          class="btn attempt-next-btn bg-m-primary text-white"
          v-on:click="nextQuestion"
        >
          Selanjutnya
        </button>
        <button
          v-else
          class="btn attempt-next-btn bg-m-primary text-white"
          v-on:click="openConfirm"
        >
          Selesai
        </button>
      </b-container>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/util/Loading.vue";
import {
  getQuizByCourse,
  getUserAttempts,
  getAttemptData,
  saveAttempt,
  proccessAttempt,
} from "@/utils/mod/Quiz";
import $ from "jquery";
import CountDown from "../../../components/util/CountDown.vue";
import {MarkCompleted} from "@/utils/mod/Completition"
export default {
  components: { Loading, CountDown },
  name: "Attempt",
  data() {
    return {
      isLoaded: false,
      loadingText: "Memuat percobaan",
      quiz: null,
      attempt: null,
      attemptData: null,
      currentPage: 0,
      nextPage: -1,
      isQuestionLoaded: false,
      questionLoadingText: "Memuat Soal",
      timeup: 0,
    };
  },
  methods: {
    isLoading() {
      if (
        this.quiz != null &&
        this.attempt != null &&
        this.attemptData != null
      ) {
        this.isLoaded = true;
      } else {
        this.isLoaded = false;
      }
    },
    async getAllData() {
      var vm = this;
      await getQuizByCourse(this.$route.params.id)
        .then((r) => {
          if (r.success) {
            r.data.quizzes.forEach((element) => {
              if (element.coursemodule == vm.$route.params.quizId) {
                vm.quiz = element;
                vm.getAttempts(element.id);
                return;
              }
            });
          } else {
            console.log(r.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getAttempts(id) {
      var vm = this;
      await getUserAttempts(id, "all")
        .then((r) => {
          if (r.success) {
            r.data.attempts.forEach((element) => {
              if (element.id == vm.$route.params.attemptid) {
                this.attempt = element;
                vm.getQuestion(element.id, 0);
                return;
              }
            });
          } else {
            console.log(r.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getQuestion(attemptid, page) {
      var vm = this;
      vm.isQuestionLoaded = false;
      await getAttemptData(attemptid, page)
        .then((r) => {
          if (r.success) {
            vm.attemptData = r.data;
            vm.isQuestionLoaded = true;
            vm.timeup = vm.attemptData.attempt.timestart + vm.quiz.timelimit;
            this.nextPage = r.data.nextpage;
            this.currentPage = r.data.questions[0].page;
          } else {
            console.log(r.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async nextQuestion() {
      var vm = this;
      this.questionLoadingText = "Menyimpan jawaban anda";
      this.isQuestionLoaded = false;
      var answernext = $("#attempt-data").serializeArray();
      await proccessAttempt(this.attempt.id, answernext)
        .then((r) => {
          vm.isQuestionLoaded = true;
          if (r.success) {
            vm.getQuestion(vm.attempt.id, vm.nextPage);
          } else {
            console.log(r.data);
          }
        })
        .catch((e) => {
          vm.isQuestionLoaded = true;
          console.log(e);
        });
    },
    async previousQuestion() {
      var vm = this;
      this.questionLoadingText = "Menyimpan jawaban anda";
      this.isQuestionLoaded = false;
      var answernext = $("#attempt-data").serializeArray();
      await proccessAttempt(this.attempt.id, answernext)
        .then((r) => {
          vm.isQuestionLoaded = true;
          if (r.success) {
            vm.getQuestion(vm.attempt.id, vm.currentPage - 1);
          } else {
            alert(r.data.exception);
          }
        })
        .catch((e) => {
          vm.isQuestionLoaded = true;
          alert(e.message);
        });
    },
    async finish() {
      var vm = this;
      this.questionLoadingText = "Menyimpan jawaban anda";
      this.isQuestionLoaded = true;
      var answernext = $("#attempt-data").serializeArray();
      let cmid = vm.$route.params.quizId;
      await proccessAttempt(this.attempt.id, answernext, 1)
        .then((r) => {
          vm.isQuestionLoaded = false;
          if (r.success) {
            return MarkCompleted(cmid)
          } else {
            alert(r.data.exception);
          }
        })
        .then((r)=>{
          if(r.success){
            if(r.data.status){
              vm.$router.replace({
              path: `/course/${vm.$route.params.id}/mod/quiz/${vm.$route.params.quizId}`,
            });
            }
          }
        })
        .catch((e) => {
          vm.isQuestionLoaded = false;
          alert(e.message);
        });
    },
    openConfirm() {
      this.$bvModal.show("submit-confirm");
    },
    hideConfirm() {
      this.$bvModal.hide("submit-confirm");
    },
    async save() {
      this.questionLoadingText = "Menyimpan jawaban anda";
      this.isQuestionLoaded = true;
      var answer = $("#attempt-data").serializeArray();
      await saveAttempt(this.attempt.id, answer)
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
      this.isQuestionLoaded = false;
      this.getQuestion(this.attempt.id, this.currentPage);
    },
    replaceImg() {
      var self = this;
      $("#attempt-data img").each(function () {
      $(this).attr("style", "max-width:95% !important;");
        let uri = $(this).attr("src");
        if (uri.includes("literaci.id") && !uri.includes('literaci.id/theme')) {
          uri = `${$(this).attr("src")}?&token=${self.$cookies.get("token")}`;
          if (!uri.includes("webservice")) {
            uri =
              uri.substring(0, 30) +
              "webservice/" +
              uri.substring(30, uri.length);
          }
          $(this).attr("src", uri);
        }
      });
    },
  },
  watch: {
    quiz: function () {
      this.isLoading();
    },
    attempt: function () {
      this.isLoading();
    },
    attemptData: function () {
      this.isLoading();
    },
  },
  mounted() {
    this.getAllData();
  },
  updated() {
    $(".answer").children("div").children("label").removeClass();
    $(".info").removeClass().addClass("d-none");
    $(".answer")
      .children("div")
      .children("div")
      .children("textarea")
      .addClass("w-100");
    $(".answer")
      .children("div")
      .children("div")
      .children("textarea")
      .addClass("border-black");
    $(".answer")
      .children("div")
      .children("div")
      .children("textarea")
      .addClass("bg-m-fourth");
    $(".answer")
      .children("div")
      .children("div")
      .children("textarea")
      .prop("placeholder", "Tulis jawaban anda disini");
    $(".answer")
      .children("input")
      .addClass("bg-m-fourth")
      .prop("placeholder", "Tulis jawaban anda disini");
    $(".im-controls").hide();
    $(".btn-link").remove();
    this.replaceImg();
  },
};
</script>
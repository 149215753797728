<template>
 <div id="app">
    <div class="drag">
        <b class="text-theory">Introduction</b>
     </div>
</div>
</template>

<script>
export default {
}
</script>

<style scoped>
.drag{
flex-direction: row;
align-items: center;
padding: 8px 5px;
height: 41px;
left: 20px;
top: 162px;
background: #FFFFFF;
border-radius: 4px;
}
.text-theory{
font-family: Nunito;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 25px;
flex: none;
order: 1;
flex-grow: 0;
margin: 12px 0px;
}

</style>
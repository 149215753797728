/**
 * Custom moodle exception
 */
class MoodleException {
    /**
     * Create new exception
     * @param {string} exceptioncode the Exception code
     * @param {object} exception Other required exception information
     * @param {string} message Message to be displayed to user
     */
    constructor(exceptioncode = "", exception = {}, message = '') {
        this.exceptioncode = exceptioncode;
        this.exception = exception
        this.message = message
    }
}

module.exports = { MoodleException }
<template>
  <div>
    <div
      v-if="value.text != null && value.text != undefined && value.text != ''"
      v-html="value.text"
    ></div>
    <div v-else class="text-left">-</div>
  </div>
</template>
<script>
export default {
  name: "pluginOnlineText",
  props: ["value"],
};
</script>
<template>
  <div>
      <loading v-if="isLoading"/>
    <Cropper
      class="cropper"
      ref="cropper"
      :src="img"
      :stencil-props="{
        aspectRatio: aspect,
      }"
    />

    <div class="crop-container">
      <b-button
        @click="cropAndUpdate"
        variant="primary"
        class="mx-2 updatePhoto"
        size="lg"
        style="max-width:50%; "
      >
        Simpan Foto
      </b-button>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { Upload64 } from "@/utils/mod/Files";
import { UpdateAvatar } from "@/utils/user/User";
import Loading from '@/components/util/Loading.vue';


export default {
  components: {
    Cropper,
    Loading
  },
  data(){
    return {
      aspect: 1 / 1,
      img: "",
      isLoading:false
    };
  },
  methods: {
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;

      // define photo profile from croped image
      return canvas.toDataURL();
    },
    cropAndUpdate: async function () {
        this.isLoading = true
      // const router = this.$router;
      const photoProfile = this.crop();
      let user = this.$cookies.get("profile");

      // declare form data
      const data = new FormData();

      // append into form data
      data.append("id", user.id);
      data.append("userpicture", photoProfile);
        let vm = this;
      await Upload64(this.$cookies.get("user"),photoProfile,'newavatar.png')
        .then(r=>{
            if(r.success){
                console.log(r)
                console.log(r.data.itemid)
                return UpdateAvatar(r.data.itemid)
            }else{
                alert('Gagal mengunggah foto')
            }    
        })
        .then(r=>{
            if(r.data.success){
                user.profileimageurl = r.data.profileimageurl
                user.profileimageurlsmall = r.data.profileimageurl
                vm.$cookies.set("profile",JSON.stringify(user));
                vm.$router.replace('/u/profile')
            }else{
                alert('Gagal mengubah foto')
            }
        })
        this.isLoading = false
    },  
  },
  mounted() {
    const addPhotoProfile = localStorage.getItem("addPhotoProfile");

    if (addPhotoProfile != undefined && addPhotoProfile != "") {
      this.img = addPhotoProfile;
    }
  },
};
</script>

<style scoped>
.cropper {
  height: calc(100vh);
  background: #ddd;
  overflow: hidden;
  width: 100%;
}

.updatePhoto {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 50% !important;
  margin-left:-25% !important;
  bottom: 1rem;
  text-align: center;
  width: calc(100% - 15px);
}
</style>
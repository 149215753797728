<template>
  <div id="user-profile-view" class="bg-m-primary" style="padding-bottom:40px;">
    <div class="top d-flex flex-column min-h-100">
      <div class="top-profile">
        <b-row>
          <b-col class="text-left text-white"><h5>Profil</h5> </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <v-img class="user-avatar" :src="avatar"></v-img>
            <strong><br />{{ name }}</strong
            ><br />
            <small>{{ email }}</small>
          </b-col>
        </b-row>
      </div>
      <b-container class="user-profile-view-nav primary-content-light  flex-grow-1 text-left">
        <b-row>
          <b-col cols="12" class="list-header mt-4">Pengaturan Akun</b-col>
        </b-row>
          <router-link to="profile/edit">
        <b-row>
            <b-col class="text-dark" cols="10">Edit Profil</b-col>
            <b-col cols="1"><img src="@/assets/images/ic/chevron-right.svg" /></b-col>
        </b-row>
          </router-link>
        <router-link to="/u/notification">
          <b-row>
          <b-col class="text-dark" cols="10">Notifikasi</b-col>
          <b-col cols="1"><img src="@/assets/images/ic/chevron-right.svg" /></b-col>
        </b-row>
        </router-link>
        <b-row>
          <b-col cols="12" class="list-header">Support</b-col>
        </b-row>
        <router-link to="/about">
        <b-row>
          <b-col class="text-dark" cols="10">Tentang</b-col>
          <b-col cols="1"><img src="@/assets/images/ic/chevron-right.svg" /></b-col>
        </b-row>
        </router-link>
        <router-link to="/terms">
        <b-row>
          <b-col class="text-dark" cols="10">Syarat dan Ketentuan</b-col>
          <b-col cols="1"><img src="@/assets/images/ic/chevron-right.svg" /></b-col>
        </b-row>
        </router-link>
        <b-row v-on:click="toTest">
          <b-col class="text-dark" cols="10">Beri Rating</b-col>
          <b-col cols="1"><img src="@/assets/images/ic/chevron-right.svg" /></b-col>
        </b-row>
        <b-row>
          <b-col v-on:click="logout()" class="text-danger">
            <b>Logout</b>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserProfileView",
  data: () => {
    return {
      name: "Samuel L. Jackson",
      avatar:
        "https://upload.wikimedia.org/wikipedia/commons/a/a9/Samuel_L._Jackson_2019_by_Glenn_Francis.jpg",
      email: "amien@mail.com",
      userProfile: ""
    };
  },
  methods: {
    logout: function () {
      this.$cookies.remove("user");
      this.$cookies.remove("token");
      this.$cookies.remove("profile");
      this.$router.replace("/login").catch(() => {});
    },
    toTest(){
      this.$router.replace('/test')
    }
  },
  mounted() {
    var profile = this.$cookies.get("profile");
    this.name = profile.fullname;
    this.avatar = profile.profileimageurlsmall;
    this.email = profile.username;
  },
};
</script>

<style scoped>
.user-avatar {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 0px auto;
  border: 2px solid #fff;
}
.top-profile {
  color: #fff;
  padding: 10px 20px 30px 20px;
}

#user-profile-view {
  background-color: #302ea7;
}
.user-profile-view-nav {
  border-radius: 20px 20px 0px 0px;
  background-color: #f1f1fa;
  padding: 0 25px 20px 20px;
}
.user-profile-view-nav .row div {
  padding-top : 10px !important;
  padding-bottom : 10px !important;
}

.list-header {
  color: #a3a3a3;
}
</style>
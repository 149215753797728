<template>
  <v-main>
    <div class="min-h-100" v-if="data_count > 0">
      <div v-for="item in courses" :key="item.id">
        <course-item v-if="!item.completed" :course="item" />
      </div>
    </div>
    <div v-else>
      <b-container class="min-h-100">
        <b-row>
          <b-col>
            <img center src="@/assets/images/boy1.svg" />
            <br />
            <br />
            <br />
            <h5><b class="text-grey">Kamu belum menyelesaikan course!</b></h5>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </v-main>
</template>
<script>
import CourseItem from "@/components/CourseItem";
import { getMyCourses } from "@/utils/mod/Courses";
export default {
  data: () => {
    return {
      courses: [],
      dataloaded: false,
      data_count: 0,
    };
  },
  components: {
    "course-item": CourseItem,
  },
  methods: {
    getOnGoing: async function (id) {
      this.dataloaded = false;
      this.data_count = 0;
      getMyCourses(id)
        .then((r) => {
          if (Array.isArray(r.data) && r.data.length > 0) {
            this.dataloaded = true;
            this.courses = r.data;
            r.data.forEach(element => {
              if(!element.completed){
                this.data_count += 1;
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getOnGoing(this.$cookies.get("user"));
  },
};
</script>
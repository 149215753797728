<template>
  <div class="login h-100 bg-m-primary">
    <loading v-if='isLoading'/>
    <div class="top min-h-100 d-flex flex-column">
      <div>
        <div class="text-center">
          <img id="logo" src="@/assets/logo-square.svg" />
        </div>
        <br/>
      </div>
      <div class="login-part flex-grow-1 w-100">
        <b-container>
          <b-row>
            <b-col>
              <b-form-group>
                <!-- Form -->
                <!-- Nama Lengkap -->
                <b-form-input
                  :required="true"
                  class="login-form"
                  v-model="fullname"
                  placeholder="Nama Lengkap"
                />

                <!-- Alamat Email -->
                <b-form-input
                  :required="true"
                  type="email"
                  class="login-form"
                  v-model="email"
                  placeholder="Alamat Email"
                />

                <!-- Tanggal Lahir -->
                <div>
                  <b-form-datepicker
                    :required="true"
                    :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
                    :show-decade-nav="true"
                    :nav-next-decade="false"
                    :hide-header="true"
                    @input="onClickDateofBirth"
                    locale="id"
                    class="login-form"
                    v-model="dateofbirth"
                    placeholder="Tanggal Lahir"
                  >
                  </b-form-datepicker>
                  <img 
                    class="input-icon-right" 
                    src="@/assets/images/ic/calendar1.svg"
                    alt="" 
                    srcset="">
                </div>

                <!-- Jenis Kelamin -->
                <div>
                  <b-form-select
                    :options="[
                      {value: null, text: 'Pilih Jenis Kelamin', disabled: true},
                      {value: 'L', text: 'Laki - Laki'},
                      {value: 'P', text: 'Perempuan'}
                    ]"
                    v-model="gender"
                    class="login-form"
                  >
                  </b-form-select>
                </div>

                <!-- Kelas -->
                <div>
                  <b-form-select
                    :options="gradeOptions"
                    v-model="grade"
                    class="login-form"
                  >
                  </b-form-select>
                </div>

                <!-- No Handphone -->
                 <b-input-group class="login-form">
                   <b-input-group-prepend>
                    <b-button class="prepend-button">🇮🇩 +62</b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    :required="true"
                    :maxlength="13"
                    @keypress="isNumber"
                    type="text"
                    v-model.number="phonenumber"
                    placeholder="No Handphone"
                  />
                </b-input-group>
                

                <!-- Password -->
                <div>
                  <b-form-input
                    :required="true"                    
                    :type="`${showPassword ? 'text' : 'password'}`"
                    :class="{
                      'is-valid': password === confirmPassword,
                      'is-invalid': password !== confirmPassword
                    }"
                    class="login-form"
                    v-model="password"
                    placeholder="Password"
                  />
                  <img 
                    :src="require(`@/assets/images/ic/show-toggle${showPassword ? '-on' : ''}.svg`)"
                    @click="showPassword = !showPassword"
                    class="input-icon-right" 
                    alt="" 
                    srcset="">
                </div>

                <div>
                  <b-form-input
                    :required="true"
                    :type="`${showConfirmPassword ? 'text' : 'password'}`"
                    :class="{
                      'is-valid': password === confirmPassword,
                      'is-invalid': password !== confirmPassword
                    }"
                    class="login-form"
                    v-model="confirmPassword"
                    placeholder="Konfirmasi Password"
                  />
                  <img 
                    :src="require(`@/assets/images/ic/show-toggle${showConfirmPassword ? '-on' : ''}.svg`)"
                    @click="showConfirmPassword = !showConfirmPassword"
                    class="input-icon-right" 
                    alt="" 
                    srcset="">
                </div>

                <b-button type="submit" v-on:click="register" class="login-btn">
                  DAFTAR
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row  v-if="!isHidden">
            <b-col  sm="12" md="12" xs="12">
              <p class="err-msg">
                <b>Terdapat kesalahan :</b>
                <ul>
                  <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                </ul>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr class="break-line">
              <p>
                Sudah punya akun?
                <router-link to="/login">Masuk</router-link>
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/util/Loading";
export default {
  components: {
    Loading,
  },
  name: "Register",
  data() {
    return {
      errors: [],
      errorMsg: "",
      isHidden: true,
      showPassword: false,
      showConfirmPassword: false,
      gradeOptions: [],
      fullname: "",
      email: "",
      gender: null,
      grade: null,
      dateofbirth: "",
      phonenumber: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
    };
  }, // end data
  methods: {
    register: async function () {
      const router = this.$router;
      this.isHidden = true;
      // validate input parameter
      let errors = this.errors;
      this.validateForm();

      // all input parameter
      const fullname = this.fullname;
      let firstname = "";
      let lastname = "";
      lastname = fullname.split(" ").pop();
      firstname = fullname.replace(/\w+[.!?]?$/, "");

      const email = this.email;
      const password = this.password;
      const country = "Indonesia";
      const city = {
        dateofbirth: this.dateofbirth,
        gender: this.gender,
        grade: this.grade,
        city: "Yogyakarta",
        phonenumber: `+62${this.phonenumber}`,
      };
      let wstoken = "c6d0bff6ada3697e63c4622f90d8f25e";
      let wsfunction = "auth_email_signup_user";
      let moodlewsrestformat = "json";
      // declare form data
      const data = new FormData();

      // append into form data
      data.append("firstname", firstname);
      data.append("lastname", lastname);
      data.append("username", email);
      data.append("email", email);
      data.append("password", password);
      data.append("country", country);
      data.append("city", JSON.stringify(city));
      data.append("wstoken", wstoken);
      data.append("wsfunction", wsfunction);
      data.append("moodlewsrestformat", moodlewsrestformat);

      const config = {
        method: "post",
        url: "https://dashboard.literaci.id/webservice/rest/server.php",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.isLoading = true;
      let result = await this.axios(config)
         if (result.data.success) {
          this.isHidden = true;
            router.push("/email-confirmation");
          } else {
            this.errors = [];
            this.errors = result.data.warnings.map((error)=> error.message)
            this.isHidden = false
          }
      this.isLoading = false;
    }, // end register
    onClickDateofBirth(dob) {
      this.dateofbirth = dob;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateForm() {
      this.errors = [];

      if (!this.fullname) {
        this.errors.push("Mohon isikan nama lengkap siswa");
      }
      if (!this.email) {
        this.errors.push("Mohon isikan alamat email siswa");
      }
      if (!this.password || !this.confirmPassword) {
        this.errors.push("Mohon isikan password");
      }
      if (!this.dateofbirth) {
        this.errors.push("Mohon isikan tanggal lahir siswa");
      }
      if (!this.grade) {
        this.errors.push("Mohon isikan kelas siswa");
      }
      if (!this.gender) {
        this.errors.push("Mohon isikan jenis kelamin siswa");
      }
      if (!this.phonenumber) {
        this.errors.push("Mohon isikan no telepon kamu");
      }
      if (this.password != this.confirmPassword) {
        this.errors.push("Password dan Konfirmasi password berbeda!");
      }

      if (this.errors.length != 0) {
        this.isHidden = false;
      } else {
        this.isHidden = true;
      }
    },
  }, // end method
  mounted() {
    this.gradeOptions = [
      { value: null, text: "Pilih Kelas Kamu", disabled: true },
      { value: "1", text: "SD Kelas 1" },
      { value: "2", text: "SD Kelas 2" },
      { value: "3", text: "SD Kelas 3" },
      { value: "4", text: "SD Kelas 4" },
      { value: "5", text: "SD Kelas 5" },
      { value: "6", text: "SD Kelas 6" },
      { value: "7", text: "SMP Kelas 7" },
      { value: "8", text: "SMP Kelas 8" },
      { value: "9", text: "SMP Kelas 9" },
    ];
  },
};
</script>

<style>
.content-login {
  background-color: #302ea7;
}

#logo {
  margin-top: 20px;
}

.err-msg {
  color: #f44e4e;
  text-align: left;
}

.forgot-password {
  padding-right: 5px;
  color: #302ea7;
  /* override app default */
  text-align: right !important;
}

.app-title {
  color: #fff;
  margin-top: 50px;
}

.app-sub-title {
  color: #fff;
  margin-bottom: 50px;
}

.login-form {
  margin-bottom: 10px;
  /* override bootstrap default */
  border-radius: 10px !important;
  flex: 1 1 auto;
}

.login-part {
  background-color: #f1f1fa;
  padding: 20px;
  padding-top: 15px;
  border-radius: 20px 20px 0px 0px;
  margin: 0 auto;
}

.login {
  background-color: #f1f1fa;
  height: 100%;
}

.login-btn {
  color: #fff;
  /* override bootstrap default */
  background-color: #302ea7 !important;
  width: 100%;
  margin-top: 10px;
}

.bi-calendar {
  display: none;
}

.b-calendar-inner,
.dropdown-menu {
  width: 100% !important;
}

.b-form-datepicker .h-auto:first-child {
  display: none;
}

.b-form-datepicker .dropdown-menu {
  transform: translate3d(0px, calc(50vh - 500px), 0px) !important;
}

.b-form-datepicker label {
  text-align: left;
  padding: 0.375rem 0.75rem !important;
}

.form-control,
.login-form,
.input-group-prepend,
.btn {
  border: none !important;
}

.login-btn {
  border: none !important;
  width: calc(100% - 2px);
}

.prepend-button {
  background-color: #302ea7 !important;
  color: white;
}

.form-control.is-valid,
.form-control.is-invalid {
  background-image: none !important;
}

.form-group {
  margin-bottom: 0px !important;
}

.break-line {
  margin: 5px 0 !important;
}
</style>
<template>
    <div id="teacher-view">
        <router-view class="teacher-view"/>
        <user-nav/>
    </div>
</template>

<script>
import UserNavigation from '@/components/UserNavigation'
export default {
    name:'TeacherView',
    components:{
        'user-nav':UserNavigation
    }
}
</script>

<style scoped>
#teacher-view{
    background-color: #f1f1fa;
    min-height: 100%;
}
.teacher-view{
    padding-bottom: 56px;
}
</style>
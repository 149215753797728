<template>
  <div id="add-course" class="h-100 d-flex flex-column text-light-grey">
      <v-container id="add-course-header">
        <v-row class="top-row">
          <v-col cols="1" xs="1" align-self="center">
            <router-link to="">
              <img src="@/assets/images/ic/back.svg"/>
            </router-link>
          </v-col>
          <v-col  class="text-nav text-left text-white" cols="8" xs="8" align-self="left">
            <b>Tambah Course</b>
          </v-col>
        </v-row>
      </v-container>
      <br/>
      <b-container class="add-content flex-grow-1 min-h-100">
        <b-row class="add-menu">
          <b-col cols="4" xs="4" align-self="center">
            <router-link class="text-menu text-light-grey"
             to="/addcourse/" exact>
            <b>DESKRIPSI</b>
            </router-link>
          </b-col>

          <b-col cols="4" xs="4" align-self="center">
           <router-link class="text-menu text-light-grey"
              to="/addcourse/access"
              exact
              ><b>AKSESIBILITAS</b>
            </router-link>
          </b-col>

          <b-col cols="4" xs="4" align-self="center">
           <router-link class="text-menu text-light-grey"
              to="/addcourse/theory"
              exact
              ><b>MATERI</b>
            </router-link>
            </b-col>
        </b-row>
        <router-view />
        <add-course-button/>
      </b-container> 
  </div>
</template>

<script>
import AddCourseButton from '@/components/AddCourseButton.vue';

export default {
  name: "AddCourse",
  components: { AddCourseButton },
};
</script>

<style scoped>
#add-course {
  background: #302ea7;  
  background-image: url("~@/assets/images/accent/1.svg"),
    url("~@/assets/images/accent/2.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right top;
}
#add-course-header{
  padding-top: 20px;
  color: #fff;
}

.top-row {
  padding: 0px;
}
.top-title {
  font-size: 1.2rem;
}
.add-content {
  border-radius: 0px 0px 0px 0px;
  background:#f1f1fa;
}
.text-menu {
  font-size: 0.8rem;
}
.text-nav{
  font-size: 1.2rem;
}
</style>
<template>
  <div class="px-5">
    <loading v-if="isLoading"/>
    <img class="img-margin" src="@/assets/images/ic/email-confirmation.svg" />
    <h1><strong><br>Verifikasi Akun</strong></h1>
    <p>Klik pada link yang kami kirimkan melalui email anda untuk melanjutkan dan memverifikasi bahwa itu memang anda</p>

    <router-link to="/login">
      <b-button class="login-btn">MASUK</b-button>
    </router-link>
    <br>
    <br>
    <br/>
    <span>Belum Menerima Email ?   </span>
    <span class="text-primary" v-on:click="resendConfirmation()">
        Kirim Ulang Email
    </span>
  </div>
</template>

<script>
import { CallService } from '../../utils/MoodleWS';
import Loading from "../../components/util/Loading.vue";

export default {
  data(){
    return {
      isLoading:false
    }
  },
  name: "EmailConfirmation",
  methods:{
    async resendConfirmation(){
      this.isLoading = true
      let user = this.$store.getters.getToBeConfirmed
      let vm = this
      if(user == null){
        this.$router.replace('/')
      }else{
        let param = {
          username:user.uname,
          password:user.pwd,
          redirect:'/'
        }
        await CallService('POST','core_auth_resend_confirmation_email',true,null,param)
          .then(r =>{
            if(r.success){
              vm.$store.commit("setUserToBeConfirmed",null)
              alert('Email telah dikirim!')
            }else{
              console.log(r)
            }
          })
        console.log(user)
      }
      this.isLoading = false
    }
  },
  components:{
    Loading
  }
};
</script>

<style scoped>
.img-margin {
  margin-top: calc(10vh);
  width: 50%;
}

h1{
    font-weight: 800;
}
</style>
<template>
  <div id="terms-condition" class="text-justify bg-m-primary">
    <loading v-if="isLoading" />
    <div class="top d-flex flex-column min-h-100">
      <b-container class="text-left text-white">
        <!-- PAGE TITLE -->
        <b-row align-v="center">
          <b-col cols="1" class="text-left">
            <span class="back-icon icon" v-on:click="kembali()"></span>
          </b-col>
          <b-col class="text-left">
            <span
              ><strong><h4>Lupa Password</h4></strong></span
            >
          </b-col>
        </b-row>
      </b-container>
      <!-- CONTENT -->
      <b-container class="primary-content-light flex-grow-1">
        <h4 class="text-primary">Temukan akun anda!</h4>
        <form class="form dashed-box">
          <b-input v-model="email" :placeholder="'Cari email anda ...'" />
        </form>
        <br />
        <div class="text-right">
          <button
            class="btn bg-m-primary text-white"
            v-on:click="requestConfrim()"
          >
            Cari Email
          </button>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { SendForgotPasswordEmail } from "@/utils/mod/Auth";
import Loading from "../../components/util/Loading.vue";

export default {
  components: { Loading },
  name: "ForgotPassword",
  data() {
    return {
      email: null,
      isLoading: false,
    };
  },
  methods: {
    kembali() {
      this.$router.go(-1);
    },
    async requestConfrim() {
      this.isLoading = true;
      if (this.email == null) {
        alert("Email tidak boleh kosong!");
      } else {
        await SendForgotPasswordEmail(this.email).then((r) => {
          switch (r.data.status) {
            case "dataerror":
              alert(
                "Terjadi kesalahan! Silahkan periksa apakah email anda sudah benar!"
              );
              break;
            case "emailpasswordconfirmmaybesent":
              alert(
                "Berhasil! Apabila anda memasukkan email yang tepat, sistem sudah mengirimkan tautan perubahan password ke alamat email anda!"
              );
              break;
            case "emailpasswordconfirmnotsent":
              alert("Terjadi kesalahan! User tidak ditemukan!");
              break;
            case "emailpasswordconfirmnoemail":
              alert(
                "Kami tidak dapat menemukan alamat email anda! Silahkan hubungi admin untuk mengganti password!"
              );
              break;
            case "emailalreadysent":
              alert("Email telah dikirimkan!");
              break;
            case "emailpasswordconfirmsent":
              alert("Email telah dikirimkan, silahkan periksa email anda!");
              break;
            default:
              alert("Email telah dikirmkan!");
          }
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
const { CallService } = require("../MoodleWS")

/**
 * 
 * @param {array} courseId 
 * @returns promise
 */
var UpdateUserProfile = async function(user){
    var param ={
        users : [user]
    }
    return await CallService('POST','core_user_update_users',true,null,param)
}

var UpdateAvatar = async function(itemid){
    var param ={
        "draftitemid":itemid,
    }
    return await CallService('GET','core_user_update_picture',false,param)
}

module.exports = {UpdateUserProfile, UpdateAvatar}
<template>
  <div id="CourseDetailOverview min-h-90">
    <b-container>
      <b-row>
        <b-col cols="1 text-left"
          ><img src="@/assets/images/ic/pin.svg"
        /></b-col>
        <b-col cols="10 text-left"
          >Deskripsi
          <div class="deskripsi" id="deskripsi" v-html="this.course.summary"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {CallService} from '@/utils/MoodleWS'
import $ from 'jquery'
export default {
  name: "CourseDetailOverview",
  data: () => {
    return {
      course: Object,
    };
  },
  props: {
    courseId: String,
  },
  methods: {
    getData: async function (id) {
      var params = {
        field:"id",
        value:id,
      };

      await CallService("get", "core_course_get_courses_by_field", false, params)
      .then((r) => {
        if(r.data.courses != null && r.data.courses != undefined && Array.isArray(r.data.courses)){
          if(r.data.courses.length > 0){
              this.course = r.data.courses[0];
            }
          }
        }
      ).catch((e)=>{
        console.log(e)
      });
    }
  },
  created() {
    this.getData(this.$props.courseId);
    

  },
  watch:{
    '$props.courseId':{
      handler(newVal){
        this.getData(newVal);
      }
    }
  },
    updated(){
    var self = this;
    $("#deskripsi img").each(function () {
        var uri = `${$(this).attr("src")}?&token=${self.$cookies.get('token')}`;
        $(this).attr("src",uri)
        $(this).attr("style","max-width:95% !important;height:auto;")
      });
  }
};
</script>